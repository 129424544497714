import React, { useEffect, useState } from "react";
import "./Loading.css";
import Logo from "../assets/Logo.svg";

function Loading({ loaded = true }) {
  const [animate, setAnimate] = useState("");
  useEffect(() => {
    if (!loaded) {
      setAnimate(85);
    } else {
      setAnimate(100);
    }
  }, [loaded]);
  return (
    <div className="StackContainer ImageGradient">
      <div className="NuhaLoading StackContainer">
        <img className="NuhaLoadingLogo" src={Logo} alt="" />
        <div className="ProgressBarContainer">
          <div className={`ProgressBar loaded-${animate}`} />
        </div>
      </div>
    </div>
  );
}

export default Loading;
