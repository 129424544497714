import React, { useEffect, useState } from "react";
import styles from "./SelectGame.module.css";
import Vector from "../assets/ToofanVector.svg";
import { useLanguageThemeContext } from "../LanguageThemeContext";
import { useNavigate } from "react-router-dom";
import Loading from "../Components/Loading";
import Footer from "../Components/Footer";

function SelectGame() {
  const { language, clear } = useLanguageThemeContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loading />;
  } else
    return (
      <div className="StackContainer">
        <div className="CloseBtn" onClick={clear} />
        <div
          className="LeaderBoardBtn"
          onClick={() => navigate("/leaderboard")}
        />
        <div className={`StackContainer ${styles.HomeHeader}`}>
          <img src={Vector} className="CardToofanLogo" alt="Toofan Vector" />
          <div className={styles.HomeTitle}>
            {language === "ar"
              ? "اختر لعبة من القائمة"
              : "Choose a Game from the list"}
          </div>
        </div>

        <div className={`StackContainer card`}>
          <div className={styles.HomeCategoriesRow1}>
            <div
              className={`StackContainer ${styles.Category}`}
              onClick={() => {
                navigate("/splash/1");
              }}
            >
              <div className={styles.CategoryTitle}>
                {language === "en" ? "Characters" : "شخصيات"}
              </div>
              <div
                className={`${styles.CategoryImage} ${styles.CharactersImg}`}
              ></div>
            </div>
            <div
              className={`StackContainer ${styles.Category}`}
              // onClick={() => {
              //   navigate("/splash/2");
              // }}
            >
              <div className={styles.CategoryTitle}>
                {language === "en" ? "Al-Aqsa" : "الأقصى"}
              </div>
              <div className={`${styles.CategoryImage} ${styles.QudsImg}`} />
            </div>
          </div>

          <div className={styles.HomeCategoriesRow2}>
            <div
              className={`StackContainer ${styles.Category}`}
              // onClick={() => {
              //   navigate("/splash/3");
              // }}
            >
              <div className={styles.CategoryTitle}>
                {language === "en" ? "Battles" : "معارك"}
              </div>
              <div
                className={`${styles.CategoryImage} ${styles.BattlesImg}`}
              ></div>
            </div>
            <div
              className={`StackContainer ${styles.Category}`}
              // onClick={() => {
              //   navigate("/splash/4");
              // }}
            >
              <div className={styles.CategoryTitle}>
                {language === "en" ? "Areas" : "أماكن"}
              </div>
              <div
                className={`${styles.CategoryImage} ${styles.PlacesImg}`}
              ></div>
            </div>
          </div>

          <Footer opacity />
        </div>
      </div>
    );
}

export default SelectGame;
