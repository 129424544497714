import React from "react";
import Logo from "../assets/Logo.svg";

const Footer = ({ opacity = false }) => {
  return (
    <div className={`NuhaFooter ${opacity && "Opacity"}`}>
      <img className="NuhaFooterLogo" src={Logo} alt="" />
      <div>
        Powered by <u>Nuha</u>
      </div>
    </div>
  );
};

export default Footer;
