import React, { useState, useEffect, useRef } from "react";
import styles from "./Register.module.css";
import { useLanguageThemeContext } from "../LanguageThemeContext";
import { postData } from "../fetch";
import Vector from "../assets/ToofanVector.svg";
import Loading from "../Components/Loading";
import Countries from "../Appendices/ArabicCountriesList";
import SearchSelect from "../Components/SearchSelect";
import Footer from "../Components/Footer";

const Register = () => {

  const { language, setName, setId } = useLanguageThemeContext();
  const [userData, setUserData] = useState({
    name: "",
    age: "",
    gender: "",
    country: "",
  });
  const [loading, setLoading] = useState(false)

  //////// For Age and Name
  const handleInputChange = (e) => {
    let { name, value } = e.target;

    if (name === "name") {
      
    }
    else if (name === "age") {
      value = arabicToEnglishNumbers(value).replace(/\D/g, '');
    }
    setUserData({
      ...userData,
      [name]: value,
    });
  };
  const arabicToEnglishNumbers = (str) => {
    const arabicNumbers = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
    return String(str).replace(/[٠-٩]/g, (digit) => arabicNumbers.indexOf(digit).toString());
  };
  //////////////



  //////// For SearchSelect
  const countryOptions = Countries.map((country) => ({
    value: country.code, // assuming you want the country code to be sent to the server
    label: language === "en" ? country.name : country.name, // here you would add the logic for language-based labels
  }));

  const handleCountryChange = (selectedOption) => {
    setUserData({
      ...userData,
      country: selectedOption.value,
    });
  };

  const normalizeArabicCharacters = (str) => {
    return str.replace(/أ|إ|آ/g, 'ا');
  };
  const normalizedCountryOptions = countryOptions.map((option) => ({
    ...option,
    label: normalizeArabicCharacters(option.label),
  }));

  const customFilterOption = (option, rawInput) => {
    const normalizedInput = normalizeArabicCharacters(rawInput);
    const normalizedLabel = normalizeArabicCharacters(option.label);
    return normalizedLabel.includes(normalizedInput);
  };
/////////////////

const submit = (e) => {
  e.preventDefault();
  const timeRegistered = new Date().toLocaleString("en-US", {
    timeZone: "Asia/Amman",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  setLoading(true);
  postData("users", { ...userData, timeRegistered, score: 0 }).then((response) => {
    setName(userData.name);
    setId(response.name);
  });
};


  
const selectRef = useRef(null);

useEffect(() => {
  const handleFocus = (e) => {
    e.target.options[0].style.color = "red";
  };
  const handleBlur = (e) => {
    e.target.options[0].style.color = "initial";
  };

  const selects = selectRef.current.querySelectorAll("select");
  selects.forEach((select) => {
    select.addEventListener("focus", handleFocus);
    select.addEventListener("blur", handleBlur);
  });

  return () => {
    selects.forEach((select) => {
      select.removeEventListener("focus", handleFocus);
      select.removeEventListener("blur", handleBlur);
    });
  };
}, []);





  if (!loading) return (
    <form
      ref={selectRef}
      className="StackContainer ImageGradient"
      onSubmit={submit}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <img src={Vector} className="ToofanLogo" alt="Toofan Vector" />
      <div className={styles.FormContainer}>
        <input
          required
          type="text"
          name="name"
          className={`${language === "en" ? "ltr" : "rtl"} ${styles.FormInput}`}
          placeholder={language === "en" ? "Name" : "الاسم"}
          value={userData.name}
          onChange={(e) => {
            const inputValue = e.target.value;
            handleInputChange(e); // Assuming handleInputChange is your existing change handler
            if (inputValue.length < 2 || inputValue.length > 18) {
              e.target.setCustomValidity(language === "en" ? "Please enter a valid name" : "الرجاء إدخال اسم صالح");
            } else {
              e.target.setCustomValidity("");
            }
          }}
        />
        <input
          required
          type="text"
          inputMode="numeric" // Suggests a numeric keyboard on mobile devices
          pattern="\d*" // Ensures only numeric input is allowed
          name="age"
          className={`${language === "en" ? "ltr" : "rtl"} ${styles.FormInput}`}
          placeholder={language === "en" ? "Age" : "العمر"}
          value={userData.age}
          onChange={handleInputChange}
        />
        <select
          required
          name="gender"
          className={`${language === "en" ? "ltr" : "rtl"} ${styles.FormInput}`}
          value={userData.gender}
          onChange={handleInputChange}
        >
          <option hidden value="">{language === "en" ? "Gender" : "الجنس"}</option>
          <option value="male">{language === "en" ? "Male" : "ذكر"}</option>
          <option value="female">
            {language === "en" ? "Female" : "أنثى"}
          </option>
        </select>
        <SearchSelect
          options={countryOptions}
          onChange={handleCountryChange}
          placeholder={language === "en" ? "Country" : "الدولة"}
          isSearchable={true}
          filterOption={customFilterOption}
        />
      </div>
      <button type="submit" className={`ActionBtn ${styles.FormBtnMargin}`}>{language === "en" ? "Start" : "ابدأ"}</button>
      <Footer />
    </form>
  );
  else return <Loading loaded={true} />
};

export default Register;

