import React, { createContext, useContext, useEffect, useState } from "react";
import { putData } from "./fetch";

const LanguageThemeContext = createContext();

export function LanguageThemeContextProvider({ children }) {
  const [language, setLanguage] = useState("ar");
  const [theme, setTheme] = useState("");

  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [score, setScore] = useState(0);
  const [latestScore, setLatestScore] = useState(0);

  const clear = () => {
    setTheme("");
    setName("");
    setScore(0);
    setLatestScore(0);
    localStorage.clear();
  };

  const updateScore = (qScore) => {
    return new Promise((resolve) => {
      setLatestScore(qScore);
      setScore(prevScore => {
        const updatedScore = prevScore + qScore;
        putData(`users/${id}/score`, updatedScore).then(() => {
          resolve(updatedScore); // Resolve when data is posted
        });
        return updatedScore;
      });
    });
  };

  useEffect(() => {
    if (score > 0) {
      putData(`users/${id}/score`, score);
    }
  }, [id, score]);

  useEffect(() => {
    if (name !== "") {
      localStorage.setItem("name", name);
    }
  }, [name]);
  useEffect(() => {
    if (id !== "") {
      localStorage.setItem("id", id);
    }
  }, [id]);
  useEffect(() => {
    if (score > 0)
      setTimeout(() => {
        localStorage.setItem("score", score);
      }, 500);
  }, [score]);
  useEffect(() => {
    if (latestScore > 0)
      setTimeout(() => {
        localStorage.setItem("latestScore", latestScore);
      }, 500);
  }, [latestScore]);

  useEffect(() => {
    if (localStorage.getItem("name")) {
      setName(localStorage.getItem("name"));
    }
  }, []);
  useEffect(() => {
    if (localStorage.getItem("id")) {
      setId(localStorage.getItem("id"));
    }
  }, []);
  useEffect(() => {
    if (localStorage.getItem("score")) {
      setScore(Number(localStorage.getItem("score")));
    }
  }, []);
  useEffect(() => {
    if (localStorage.getItem("latestScore")) {
      setLatestScore(Number(localStorage.getItem("latestScore")));
    }
  }, []);

  return (
    <LanguageThemeContext.Provider
      value={{
        language,
        setLanguage,
        theme,
        setTheme,
        name,
        setName,
        score,
        updateScore,
        latestScore,
        clear,
        setId,
        id,
      }}
    >
      {children}
    </LanguageThemeContext.Provider>
  );
}

export function useLanguageThemeContext() {
  return useContext(LanguageThemeContext);
}
