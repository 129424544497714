import React, { useEffect, useState } from "react";
import styles from "./GameSplash.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useLanguageThemeContext } from "../LanguageThemeContext";
import Footer from "../Components/Footer";

const GameSplash = () => {
  const [data, setData] = useState({});
  const [category, setCategory] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const { language } = useLanguageThemeContext();
  const randomQuizType = Math.random() < 0.5 ? "TF" : "Select";

  useEffect(() => {
    switch (id) {
      case "1":
        setData({
          img: "",
          en: "Random questions about some characters",
          ar: "أسئلة متنوعة عن الشخصيات",
          quiz: randomQuizType,
        });
        setCategory("CharactersImg");
        break;
      case "2":
        setData({
          img: "",
          en: "Random questions about Al-Aqsa Mosque",
          ar: "أسئلة متنوعة عن المسجد الأقصى المبارك",
          quiz: randomQuizType,
        });
        setCategory("QudsImg");
        break;
      case "3":
        setData({
          img: "",
          en: "Random questions about some battles",
          ar: "أسئلة متنوعة عن المعارك",
          quiz: randomQuizType,
        });
        setCategory("BattlesImg");
        break;
      case "4":
        setData({
          img: "",
          en: "Random questions about some areas",
          ar: "أسئلة متنوعة عن الأماكن",
          quiz: randomQuizType,
        });
        setCategory("PlacesImg");
        break;
      default:
        break;
    }
  }, [id, randomQuizType]);

  return (
    <div className="StackContainer Gradient">
      <div className="CloseBtn" onClick={() => navigate("/")} />
      <div className={`${styles[category]} ${styles.CategoryIcon}`} />
      <div
        className={`${styles.InfoText} ${
          language === "ar" ? styles.rtl : styles.ltr
        }`}
      >
        {language === "ar" ? (
          <div>
            شخصيات تاريخية فلسيطينة
            <br />
            في مختلف المجالات
          </div>
        ) : (
          <div>
            Paste Here
            <br />
            Paste Here
          </div>
        )}
      </div>

      <div className={styles.InfoWindow}>
        <div className={randomQuizType === "TF" ? styles.TF : styles.Select} />
        <div>
          {language === "ar" ? (
            <div>
              حرّك البطاقات للإجابة
              <br />
              قبل انتهاء الوقت
            </div>
          ) : (
            <div>
              Paste Here
              <br />
              Paste Here
            </div>
          )}
        </div>
      </div>

      <div
        onClick={() => navigate(`/game/${id}`, { state: { type: data.quiz } })}
        className={`ActionBtn ${styles.InfoBtnMargin}`}
      >
        {language === "ar" ? "ابدأ" : "Start"}
      </div>
      <Footer />
    </div>
  );
};

export default GameSplash;
