const Countries = [
	{
			"code": "AD",
			"name": "أندورا",
			"dialCode": "+376"
	},
	{
			"code": "AE",
			"name": "الامارات العربية المتحدة",
			"dialCode": "+971"
	},
	{
			"code": "AF",
			"name": "أفغانستان",
			"dialCode": "+93"
	},
	{
			"code": "AG",
			"name": "أنتيجوا وبربودا",
			"dialCode": "+1"
	},
	{
			"code": "AI",
			"name": "أنجويلا",
			"dialCode": "+1"
	},
	{
			"code": "AL",
			"name": "ألبانيا",
			"dialCode": "+355"
	},
	{
			"code": "AM",
			"name": "أرمينيا",
			"dialCode": "+374"
	},
	{
			"code": "AO",
			"name": "أنجولا",
			"dialCode": "+244"
	},
	{
			"code": "AQ",
			"name": "القطب الجنوبي",
			"dialCode": "+672"
	},
	{
			"code": "AR",
			"name": "الأرجنتين",
			"dialCode": "+54"
	},
	{
			"code": "AS",
			"name": "ساموا الأمريكية",
			"dialCode": "+1"
	},
	{
			"code": "AT",
			"name": "النمسا",
			"dialCode": "+43"
	},
	{
			"code": "AU",
			"name": "أستراليا",
			"dialCode": "+61"
	},
	{
			"code": "AW",
			"name": "آروبا",
			"dialCode": "+297"
	},
	{
			"code": "AX",
			"name": "جزر أولان",
			"dialCode": "+358"
	},
	{
			"code": "AZ",
			"name": "أذربيجان",
			"dialCode": "+994"
	},
	{
			"code": "BA",
			"name": "البوسنة والهرسك",
			"dialCode": "+387"
	},
	{
			"code": "BB",
			"name": "بربادوس",
			"dialCode": "+1"
	},
	{
			"code": "BD",
			"name": "بنجلاديش",
			"dialCode": "+880"
	},
	{
			"code": "BE",
			"name": "بلجيكا",
			"dialCode": "+32"
	},
	{
			"code": "BF",
			"name": "بوركينا فاسو",
			"dialCode": "+226"
	},
	{
			"code": "BG",
			"name": "بلغاريا",
			"dialCode": "+359"
	},
	{
			"code": "BH",
			"name": "البحرين",
			"dialCode": "+973"
	},
	{
			"code": "BI",
			"name": "بوروندي",
			"dialCode": "+257"
	},
	{
			"code": "BJ",
			"name": "بنين",
			"dialCode": "+229"
	},
	{
			"code": "BL",
			"name": "سان بارتيلمي",
			"dialCode": "+590"
	},
	{
			"code": "BM",
			"name": "برمودا",
			"dialCode": "+1"
	},
	{
			"code": "BN",
			"name": "بروناي",
			"dialCode": "+673"
	},
	{
			"code": "BO",
			"name": "بوليفيا",
			"dialCode": "+591"
	},
	{
			"code": "BQ",
			"name": "بونير",
			"dialCode": "+599"
	},
	{
			"code": "BR",
			"name": "البرازيل",
			"dialCode": "+55"
	},
	{
			"code": "BS",
			"name": "الباهاما",
			"dialCode": "+1"
	},
	{
			"code": "BT",
			"name": "بوتان",
			"dialCode": "+975"
	},
	{
			"code": "BV",
			"name": "جزيرة بوفيه",
			"dialCode": "+47"
	},
	{
			"code": "BW",
			"name": "بتسوانا",
			"dialCode": "+267"
	},
	{
			"code": "BY",
			"name": "روسيا البيضاء",
			"dialCode": "+375"
	},
	{
			"code": "BZ",
			"name": "بليز",
			"dialCode": "+501"
	},
	{
			"code": "CA",
			"name": "كندا",
			"dialCode": "+1"
	},
	{
			"code": "CC",
			"name": "جزر كوكوس",
			"dialCode": "+61"
	},
	{
			"code": "CD",
			"name": "جمهورية الكونغو الديمقراطية",
			"dialCode": "+243"
	},
	{
			"code": "CF",
			"name": "جمهورية افريقيا الوسطى",
			"dialCode": "+236"
	},
	{
			"code": "CG",
			"name": "الكونغو - برازافيل",
			"dialCode": "+242"
	},
	{
			"code": "CH",
			"name": "سويسرا",
			"dialCode": "+41"
	},
	{
			"code": "CI",
			"name": "ساحل العاج",
			"dialCode": "+225"
	},
	{
			"code": "CK",
			"name": "جزر كوك",
			"dialCode": "+682"
	},
	{
			"code": "CL",
			"name": "شيلي",
			"dialCode": "+56"
	},
	{
			"code": "CM",
			"name": "الكاميرون",
			"dialCode": "+237"
	},
	{
			"code": "CN",
			"name": "الصين",
			"dialCode": "+86"
	},
	{
			"code": "CO",
			"name": "كولومبيا",
			"dialCode": "+57"
	},
	{
			"code": "CR",
			"name": "كوستاريكا",
			"dialCode": "+506"
	},
	{
			"code": "CU",
			"name": "كوبا",
			"dialCode": "+53"
	},
	{
			"code": "CV",
			"name": "الرأس الأخضر",
			"dialCode": "+238"
	},
	{
			"code": "CW",
			"name": "كوراساو",
			"dialCode": "+599"
	},
	{
			"code": "CX",
			"name": "جزيرة الكريسماس",
			"dialCode": "+61"
	},
	{
			"code": "CY",
			"name": "قبرص",
			"dialCode": "+357"
	},
	{
			"code": "CZ",
			"name": "جمهورية التشيك",
			"dialCode": "+420"
	},
	{
			"code": "DE",
			"name": "ألمانيا",
			"dialCode": "+49"
	},
	{
			"code": "DJ",
			"name": "جيبوتي",
			"dialCode": "+253"
	},
	{
			"code": "DK",
			"name": "الدانمرك",
			"dialCode": "+45"
	},
	{
			"code": "DM",
			"name": "دومينيكا",
			"dialCode": "+1"
	},
	{
			"code": "DO",
			"name": "جمهورية الدومينيك",
			"dialCode": "+1"
	},
	{
			"code": "DZ",
			"name": "الجزائر",
			"dialCode": "+213"
	},
	{
			"code": "EC",
			"name": "الاكوادور",
			"dialCode": "+593"
	},
	{
			"code": "EE",
			"name": "استونيا",
			"dialCode": "+372"
	},
	{
			"code": "EG",
			"name": "مصر",
			"dialCode": "+20"
	},
	{
			"code": "EH",
			"name": "الصحراء الغربية",
			"dialCode": "+212"
	},
	{
			"code": "ER",
			"name": "اريتريا",
			"dialCode": "+291"
	},
	{
			"code": "ES",
			"name": "أسبانيا",
			"dialCode": "+34"
	},
	{
			"code": "ET",
			"name": "اثيوبيا",
			"dialCode": "+251"
	},
	{
			"code": "FI",
			"name": "فنلندا",
			"dialCode": "+358"
	},
	{
			"code": "FJ",
			"name": "فيجي",
			"dialCode": "+679"
	},
	{
			"code": "FK",
			"name": "جزر فوكلاند",
			"dialCode": "+500"
	},
	{
			"code": "FM",
			"name": "ميكرونيزيا",
			"dialCode": "+691"
	},
	{
			"code": "FO",
			"name": "جزر فارو",
			"dialCode": "+298"
	},
	{
			"code": "FR",
			"name": "فرنسا",
			"dialCode": "+33"
	},
	{
			"code": "GA",
			"name": "الجابون",
			"dialCode": "+241"
	},
	{
			"code": "GB",
			"name": "المملكة المتحدة",
			"dialCode": "+44"
	},
	{
			"code": "GD",
			"name": "جرينادا",
			"dialCode": "+1"
	},
	{
			"code": "GE",
			"name": "جورجيا",
			"dialCode": "+995"
	},
	{
			"code": "GF",
			"name": "غويانا",
			"dialCode": "+594"
	},
	{
			"code": "GG",
			"name": "غيرنزي",
			"dialCode": "+44"
	},
	{
			"code": "GH",
			"name": "غانا",
			"dialCode": "+233"
	},
	{
			"code": "GI",
			"name": "جبل طارق",
			"dialCode": "+350"
	},
	{
			"code": "GL",
			"name": "جرينلاند",
			"dialCode": "+299"
	},
	{
			"code": "GM",
			"name": "غامبيا",
			"dialCode": "+220"
	},
	{
			"code": "GN",
			"name": "غينيا",
			"dialCode": "+224"
	},
	{
			"code": "GP",
			"name": "جوادلوب",
			"dialCode": "+590"
	},
	{
			"code": "GQ",
			"name": "غينيا الاستوائية",
			"dialCode": "+240"
	},
	{
			"code": "GR",
			"name": "اليونان",
			"dialCode": "+30"
	},
	{
			"code": "GS",
			"name": "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
			"dialCode": "+500"
	},
	{
			"code": "GT",
			"name": "جواتيمالا",
			"dialCode": "+502"
	},
	{
			"code": "GU",
			"name": "جوام",
			"dialCode": "+1"
	},
	{
			"code": "GW",
			"name": "غينيا بيساو",
			"dialCode": "+245"
	},
	{
			"code": "GY",
			"name": "غيانا",
			"dialCode": "+595"
	},
	{
			"code": "HK",
			"name": "هونج كونج الصينية",
			"dialCode": "+852"
	},
	{
			"code": "HM",
			"name": "جزيرة هيرد وماكدونالد",
			"dialCode": ""
	},
	{
			"code": "HN",
			"name": "هندوراس",
			"dialCode": "+504"
	},
	{
			"code": "HR",
			"name": "كرواتيا",
			"dialCode": "+385"
	},
	{
			"code": "HT",
			"name": "هايتي",
			"dialCode": "+509"
	},
	{
			"code": "HU",
			"name": "المجر",
			"dialCode": "+36"
	},
	{
			"code": "ID",
			"name": "اندونيسيا",
			"dialCode": "+62"
	},
	{
			"code": "IE",
			"name": "أيرلندا",
			"dialCode": "+353"
	},
	{
			"code": "IL",
			"name": "اسرائيل",
			"dialCode": "+972"
	},
	{
			"code": "IM",
			"name": "جزيرة مان",
			"dialCode": "+44"
	},
	{
			"code": "IN",
			"name": "الهند",
			"dialCode": "+91"
	},
	{
			"code": "IO",
			"name": "المحيط الهندي البريطاني",
			"dialCode": "+246"
	},
	{
			"code": "IQ",
			"name": "العراق",
			"dialCode": "+964"
	},
	{
			"code": "IR",
			"name": "ايران",
			"dialCode": "+98"
	},
	{
			"code": "IS",
			"name": "أيسلندا",
			"dialCode": "+354"
	},
	{
			"code": "IT",
			"name": "ايطاليا",
			"dialCode": "+39"
	},
	{
			"code": "JE",
			"name": "جيرسي",
			"dialCode": "+44"
	},
	{
			"code": "JM",
			"name": "جامايكا",
			"dialCode": "+1"
	},
	{
			"code": "JO",
			"name": "الأردن",
			"dialCode": "+962"
	},
	{
			"code": "JP",
			"name": "اليابان",
			"dialCode": "+81"
	},
	{
			"code": "KE",
			"name": "كينيا",
			"dialCode": "+254"
	},
	{
			"code": "KG",
			"name": "قرغيزستان",
			"dialCode": "+996"
	},
	{
			"code": "KH",
			"name": "كمبوديا",
			"dialCode": "+855"
	},
	{
			"code": "KI",
			"name": "كيريباتي",
			"dialCode": "+686"
	},
	{
			"code": "KM",
			"name": "جزر القمر",
			"dialCode": "+269"
	},
	{
			"code": "KN",
			"name": "سانت كيتس ونيفيس",
			"dialCode": "+1"
	},
	{
			"code": "KP",
			"name": "كوريا الشمالية",
			"dialCode": "+850"
	},
	{
			"code": "KR",
			"name": "كوريا الجنوبية",
			"dialCode": "+82"
	},
	{
			"code": "KW",
			"name": "الكويت",
			"dialCode": "+965"
	},
	{
			"code": "KY",
			"name": "جزر الكايمن",
			"dialCode": "+345"
	},
	{
			"code": "KZ",
			"name": "كازاخستان",
			"dialCode": "+7"
	},
	{
			"code": "LA",
			"name": "لاوس",
			"dialCode": "+856"
	},
	{
			"code": "LB",
			"name": "لبنان",
			"dialCode": "+961"
	},
	{
			"code": "LC",
			"name": "سانت لوسيا",
			"dialCode": "+1"
	},
	{
			"code": "LI",
			"name": "ليختنشتاين",
			"dialCode": "+423"
	},
	{
			"code": "LK",
			"name": "سريلانكا",
			"dialCode": "+94"
	},
	{
			"code": "LR",
			"name": "ليبيريا",
			"dialCode": "+231"
	},
	{
			"code": "LS",
			"name": "ليسوتو",
			"dialCode": "+266"
	},
	{
			"code": "LT",
			"name": "ليتوانيا",
			"dialCode": "+370"
	},
	{
			"code": "LU",
			"name": "لوكسمبورج",
			"dialCode": "+352"
	},
	{
			"code": "LV",
			"name": "لاتفيا",
			"dialCode": "+371"
	},
	{
			"code": "LY",
			"name": "ليبيا",
			"dialCode": "+218"
	},
	{
			"code": "MA",
			"name": "المغرب",
			"dialCode": "+212"
	},
	{
			"code": "MC",
			"name": "موناكو",
			"dialCode": "+377"
	},
	{
			"code": "MD",
			"name": "مولدافيا",
			"dialCode": "+373"
	},
	{
			"code": "ME",
			"name": "الجبل الأسود",
			"dialCode": "+382"
	},
	{
			"code": "MF",
			"name": "سانت مارتين",
			"dialCode": "+590"
	},
	{
			"code": "MG",
			"name": "مدغشقر",
			"dialCode": "+261"
	},
	{
			"code": "MH",
			"name": "جزر المارشال",
			"dialCode": "+692"
	},
	{
			"code": "MK",
			"name": "مقدونيا",
			"dialCode": "+389"
	},
	{
			"code": "ML",
			"name": "مالي",
			"dialCode": "+223"
	},
	{
			"code": "MM",
			"name": "ميانمار",
			"dialCode": "+95"
	},
	{
			"code": "MN",
			"name": "منغوليا",
			"dialCode": "+976"
	},
	{
			"code": "MO",
			"name": "ماكاو الصينية",
			"dialCode": "+853"
	},
	{
			"code": "MP",
			"name": "جزر ماريانا الشمالية",
			"dialCode": "+1"
	},
	{
			"code": "MQ",
			"name": "مارتينيك",
			"dialCode": "+596"
	},
	{
			"code": "MR",
			"name": "موريتانيا",
			"dialCode": "+222"
	},
	{
			"code": "MS",
			"name": "مونتسرات",
			"dialCode": "+1"
	},
	{
			"code": "MT",
			"name": "مالطا",
			"dialCode": "+356"
	},
	{
			"code": "MU",
			"name": "موريشيوس",
			"dialCode": "+230"
	},
	{
			"code": "MV",
			"name": "جزر الملديف",
			"dialCode": "+960"
	},
	{
			"code": "MW",
			"name": "ملاوي",
			"dialCode": "+265"
	},
	{
			"code": "MX",
			"name": "المكسيك",
			"dialCode": "+52"
	},
	{
			"code": "MY",
			"name": "ماليزيا",
			"dialCode": "+60"
	},
	{
			"code": "MZ",
			"name": "موزمبيق",
			"dialCode": "+258"
	},
	{
			"code": "NA",
			"name": "ناميبيا",
			"dialCode": "+264"
	},
	{
			"code": "NC",
			"name": "كاليدونيا الجديدة",
			"dialCode": "+687"
	},
	{
			"code": "NE",
			"name": "النيجر",
			"dialCode": "+227"
	},
	{
			"code": "NF",
			"name": "جزيرة نورفوك",
			"dialCode": "+672"
	},
	{
			"code": "NG",
			"name": "نيجيريا",
			"dialCode": "+234"
	},
	{
			"code": "NI",
			"name": "نيكاراجوا",
			"dialCode": "+505"
	},
	{
			"code": "NL",
			"name": "هولندا",
			"dialCode": "+31"
	},
	{
			"code": "NO",
			"name": "النرويج",
			"dialCode": "+47"
	},
	{
			"code": "NP",
			"name": "نيبال",
			"dialCode": "+977"
	},
	{
			"code": "NR",
			"name": "نورو",
			"dialCode": "+674"
	},
	{
			"code": "NU",
			"name": "نيوي",
			"dialCode": "+683"
	},
	{
			"code": "NZ",
			"name": "نيوزيلاندا",
			"dialCode": "+64"
	},
	{
			"code": "OM",
			"name": "عمان",
			"dialCode": "+968"
	},
	{
			"code": "PA",
			"name": "بنما",
			"dialCode": "+507"
	},
	{
			"code": "PE",
			"name": "بيرو",
			"dialCode": "+51"
	},
	{
			"code": "PF",
			"name": "بولينيزيا الفرنسية",
			"dialCode": "+689"
	},
	{
			"code": "PG",
			"name": "بابوا غينيا الجديدة",
			"dialCode": "+675"
	},
	{
			"code": "PH",
			"name": "الفيلبين",
			"dialCode": "+63"
	},
	{
			"code": "PK",
			"name": "باكستان",
			"dialCode": "+92"
	},
	{
			"code": "PL",
			"name": "بولندا",
			"dialCode": "+48"
	},
	{
			"code": "PM",
			"name": "سانت بيير وميكولون",
			"dialCode": "+508"
	},
	{
			"code": "PN",
			"name": "بتكايرن",
			"dialCode": "+872"
	},
	{
			"code": "PR",
			"name": "بورتوريكو",
			"dialCode": "+1"
	},
	{
			"code": "PS",
			"name": "فلسطين",
			"dialCode": "+970"
	},
	{
			"code": "PT",
			"name": "البرتغال",
			"dialCode": "+351"
	},
	{
			"code": "PW",
			"name": "بالاو",
			"dialCode": "+680"
	},
	{
			"code": "PY",
			"name": "باراجواي",
			"dialCode": "+595"
	},
	{
			"code": "QA",
			"name": "قطر",
			"dialCode": "+974"
	},
	{
			"code": "RE",
			"name": "روينيون",
			"dialCode": "+262"
	},
	{
			"code": "RO",
			"name": "رومانيا",
			"dialCode": "+40"
	},
	{
			"code": "RS",
			"name": "صربيا",
			"dialCode": "+381"
	},
	{
			"code": "RU",
			"name": "روسيا",
			"dialCode": "+7"
	},
	{
			"code": "RW",
			"name": "رواندا",
			"dialCode": "+250"
	},
	{
			"code": "SA",
			"name": "المملكة العربية السعودية",
			"dialCode": "+966"
	},
	{
			"code": "SB",
			"name": "جزر سليمان",
			"dialCode": "+677"
	},
	{
			"code": "SC",
			"name": "سيشل",
			"dialCode": "+248"
	},
	{
			"code": "SD",
			"name": "السودان",
			"dialCode": "+249"
	},
	{
			"code": "SE",
			"name": "السويد",
			"dialCode": "+46"
	},
	{
			"code": "SG",
			"name": "سنغافورة",
			"dialCode": "+65"
	},
	{
			"code": "SH",
			"name": "سانت هيلنا",
			"dialCode": "+290"
	},
	{
			"code": "SI",
			"name": "سلوفينيا",
			"dialCode": "+386"
	},
	{
			"code": "SJ",
			"name": "سفالبارد وجان مايان",
			"dialCode": "+47"
	},
	{
			"code": "SK",
			"name": "سلوفاكيا",
			"dialCode": "+421"
	},
	{
			"code": "SL",
			"name": "سيراليون",
			"dialCode": "+232"
	},
	{
			"code": "SM",
			"name": "سان مارينو",
			"dialCode": "+378"
	},
	{
			"code": "SN",
			"name": "السنغال",
			"dialCode": "+221"
	},
	{
			"code": "SO",
			"name": "الصومال",
			"dialCode": "+252"
	},
	{
			"code": "SR",
			"name": "سورينام",
			"dialCode": "+597"
	},
	{
			"code": "SS",
			"name": "جنوب السودان",
			"dialCode": "+211"
	},
	{
			"code": "ST",
			"name": "ساو تومي وبرينسيبي",
			"dialCode": "+239"
	},
	{
			"code": "SV",
			"name": "السلفادور",
			"dialCode": "+503"
	},
	{
			"code": "SX",
			"name": "سينت مارتن",
			"dialCode": "+1"
	},
	{
			"code": "SY",
			"name": "سوريا",
			"dialCode": "+963"
	},
	{
			"code": "SZ",
			"name": "سوازيلاند",
			"dialCode": "+268"
	},
	{
			"code": "TC",
			"name": "جزر الترك وجايكوس",
			"dialCode": "+1"
	},
	{
			"code": "TD",
			"name": "تشاد",
			"dialCode": "+235"
	},
	{
			"code": "TF",
			"name": "المقاطعات الجنوبية الفرنسية",
			"dialCode": "+262"
	},
	{
			"code": "TG",
			"name": "توجو",
			"dialCode": "+228"
	},
	{
			"code": "TH",
			"name": "تايلند",
			"dialCode": "+66"
	},
	{
			"code": "TJ",
			"name": "طاجكستان",
			"dialCode": "+992"
	},
	{
			"code": "TK",
			"name": "توكيلو",
			"dialCode": "+690"
	},
	{
			"code": "TL",
			"name": "تيمور الشرقية",
			"dialCode": "+670"
	},
	{
			"code": "TM",
			"name": "تركمانستان",
			"dialCode": "+993"
	},
	{
			"code": "TN",
			"name": "تونس",
			"dialCode": "+216"
	},
	{
			"code": "TO",
			"name": "تونجا",
			"dialCode": "+676"
	},
	{
			"code": "TR",
			"name": "تركيا",
			"dialCode": "+90"
	},
	{
			"code": "TT",
			"name": "ترينيداد وتوباغو",
			"dialCode": "+1"
	},
	{
			"code": "TV",
			"name": "توفالو",
			"dialCode": "+688"
	},
	{
			"code": "TW",
			"name": "تايوان",
			"dialCode": "+886"
	},
	{
			"code": "TZ",
			"name": "تانزانيا",
			"dialCode": "+255"
	},
	{
			"code": "UA",
			"name": "أوكرانيا",
			"dialCode": "+380"
	},
	{
			"code": "UG",
			"name": "أوغندا",
			"dialCode": "+256"
	},
	{
			"code": "UM",
			"name": "جزر الولايات المتحدة البعيدة الصغيرة",
			"dialCode": ""
	},
	{
			"code": "US",
			"name": "الولايات المتحدة الأمريكية",
			"dialCode": "+1"
	},
	{
			"code": "UY",
			"name": "أورجواي",
			"dialCode": "+598"
	},
	{
			"code": "UZ",
			"name": "أوزبكستان",
			"dialCode": "+998"
	},
	{
			"code": "VA",
			"name": "الفاتيكان",
			"dialCode": "+379"
	},
	{
			"code": "VC",
			"name": "سانت فنسنت وغرنادين",
			"dialCode": "+1"
	},
	{
			"code": "VE",
			"name": "فنزويلا",
			"dialCode": "+58"
	},
	{
			"code": "VG",
			"name": "جزر فرجين البريطانية",
			"dialCode": "+1"
	},
	{
			"code": "VI",
			"name": "جزر فرجين الأمريكية",
			"dialCode": "+1"
	},
	{
			"code": "VN",
			"name": "فيتنام",
			"dialCode": "+84"
	},
	{
			"code": "VU",
			"name": "فانواتو",
			"dialCode": "+678"
	},
	{
			"code": "WF",
			"name": "جزر والس وفوتونا",
			"dialCode": "+681"
	},
	{
			"code": "WS",
			"name": "ساموا",
			"dialCode": "+685"
	},
	{
			"code": "XK",
			"name": "كوسوفو",
			"dialCode": "+383"
	},
	{
			"code": "YE",
			"name": "اليمن",
			"dialCode": "+967"
	},
	{
			"code": "YT",
			"name": "مايوت",
			"dialCode": "+262"
	},
	{
			"code": "ZA",
			"name": "جمهورية جنوب افريقيا",
			"dialCode": "+27"
	},
	{
			"code": "ZM",
			"name": "زامبيا",
			"dialCode": "+260"
	},
	{
			"code": "ZW",
			"name": "زيمبابوي",
			"dialCode": "+263"
	}
] 

 export default Countries