import React from "react";
import styles from "./Splash.module.css";
import { useLanguageThemeContext } from "../LanguageThemeContext";
import { useNavigate } from "react-router-dom";
import Vector from "../assets/ToofanVector.svg";
import Footer from "../Components/Footer";

const Splash = () => {
  const { language } = useLanguageThemeContext();
  const navigate = useNavigate();
  const description = {
    en: "Toofan Al-Aqsa is...",
    ar: `شارك في لعبة طوفان الأقصى! مبادرة أطلقتها شركة نُهى لنشر الوعي حول القضية الفلسطينية و فضح جرائم الاحتلال من خلال تحدي معلومات. هل أنت جاهز للتحدي؟
`,
  };
  return (
    <div className="StackContainer ImageGradient">
      <img src={Vector} className="ToofanLogo" alt="Toofan Vector" />
      <div className={styles.AlertWindow}>
        <div className={styles.StartWindowText}>
          {language === "en" ? description.en : description.ar}
        </div>
      </div>

      <div
        onClick={() => navigate("/register")}
        className={`ActionBtn ${styles.StartBtnMargin}`}
      >
        ابدأ
      </div>

      <Footer />
    </div>
  );
};

export default Splash;
