import React, { useEffect, useState, useRef } from "react";
import TinderCard from "react-tinder-card";
import styles from "./TrueFalse.module.css";
import { useLanguageThemeContext } from "../LanguageThemeContext";
import debounce from "lodash/debounce";
import GameTimer from "./GameTimer";
import { useNavigate, useParams } from "react-router-dom";
import { postData } from "../fetch";
import Footer from "./Footer";

const TrueFalse = ({ questions }) => {
  const [endpoint, setEndpoint] = useState("");
  const [userAnswers, setUserAnswers] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [qScore, setQScore] = useState(0);
  const { language, name, updateScore } = useLanguageThemeContext();
  const tinderCardRef = useRef(null);
  const [pop, setPop] = useState("");
  const { id } = useParams();
  const [timeEntered, setTimeEntered] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setTimeEntered(
      new Date().toLocaleString("en-US", {
        timeZone: "Asia/Amman",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
    );
  }, []);

  useEffect(() => {
    switch (id) {
      case "1":
        setEndpoint("responses/game-one/swipe");
        break;
      case "2":
        setEndpoint("responses/game-two/swipe");
        break;
      case "3":
        setEndpoint("responses/game-three/swipe");
        break;
      case "4":
        setEndpoint("responses/game-four/swipe");
        break;
      default:
        break;
    }
  }, [id]);

  const debouncedHandleSwipe = debounce((direction) => {
    const timeAnswered = new Date().toLocaleString("en-US", {
      timeZone: "Asia/Amman",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    setUserAnswers((prevAnswers) => [
      ...prevAnswers,
      {
        questionEn: questions[currentIndex].en,
        questionAr: questions[currentIndex].ar,
        answer: direction === "right",
        correctAnswer: questions[currentIndex].correctAns,
        timeAnswered,
      },
    ]);
    setCurrentIndex(currentIndex + 1);
  }, 500);

  const handleSwipe = (direction) => {
    debouncedHandleSwipe(direction);
    correction(direction === "right");
  };

  const correction = (ans) => {
    if (questions[currentIndex].correctAns === ans) {
      setQScore((prev) => prev + 1);
      setPop("TruePop animatePopup");
    } else {
      setPop("FalsePop animatePopup");
    }
    setTimeout(() => {
      setPop("");
    }, 1000);
  };

  const end = async () => {
    const timeEnded = new Date().toLocaleString("en-US", {
      timeZone: "Asia/Amman",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    postData(endpoint, {
      name,
      userAnswers,
      timeEntered,
      timeEnded,
      score: qScore,
    })
    await updateScore(qScore); // Wait for score update and data posting to complete
    navigate("/result");
  };

  useEffect(() => {
    if (currentIndex === questions.length) end();
  }, [currentIndex, questions]);

  return (
    <div className="StackContainer">
      <div className={pop} />
      <GameTimer onEnd={end} />
      <div className={styles.cardContainer}>
        {currentIndex < questions.length && (
          <TinderCard
            ref={tinderCardRef}
            key={questions[currentIndex].id}
            className={`${styles.QuizCard} StackContainer`}
            onSwipe={handleSwipe}
            preventSwipe={["up", "down"]}
          >
            <div className={styles.QuestionOneImg}></div>
            <div className={styles.QuestionOne}>
              <div
                className={`${styles.QuestionOneText} ${
                  language === "en" ? "ltr" : "rtl"
                }`}
              >
                {language === "en"
                  ? questions[currentIndex].en
                  : questions[currentIndex].ar}
              </div>
            </div>
          </TinderCard>
        )}
      </div>

      <div className={styles.QuestionOneAnswers}>
        <div
          onClick={() => {
            if (tinderCardRef.current) {
              tinderCardRef.current.swipe("left");
            }
            handleSwipe("left");
          }}
          className={styles.FalseBtn}
        />
        <div
          onClick={() => {
            if (tinderCardRef.current) {
              tinderCardRef.current.swipe("right");
            }
            handleSwipe("right");
          }}
          className={styles.TrueBtn}
        />
      </div>
      <Footer />
    </div>
  );
};

export default TrueFalse;
