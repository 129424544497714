import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import "./SearchSelect.css"

const SearchSelect = (props) => {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	const handleWindowSizeChange = () => {
		setWindowWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	const isMobile = windowWidth <= 390;

	return (
		<Select
			options={props.options}
			onChange={props.onChange}
			placeholder={props.placeholder}
			isSearchable={true}
			styles={isMobile ? MobileStyles : DesktopStyles}
			filterOption={props.filterOption}
		/>
	);
};

export default SearchSelect;




const DesktopStyles = {
	control: (provided, state) => ({
		...provided,
		width: 310,
		height: '53px !important',
		borderRadius: 19,
		border: '2px solid #00C4FF',
		background: 'linear-gradient(to bottom, rgba(12, 38, 57, 1) 0%, rgba(12, 38, 57, 0.8) 75%, rgba(12, 38, 57, 0) 100%)',
		fontWeight: 'bold',
		fontSize: '20px',
		color: 'white',
		fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
		padding: '0px 20px',
		boxShadow: 'none', // Ensures no boxShadow at all times
		borderColor: state.isFocused ? '#00C4FF' : provided.borderColor,
		'&:hover': {
			borderColor: '#00C4FF', // Border color on hover
		},
		direction: 'rtl', // Ensures text direction is always right-to-left
	}),
	dropdownIndicator: (base) => ({
		...base,
		display: 'none',
		padding: 0,
	}),
	indicatorSeparator: (base) => ({
		...base,
		display: 'none',
		padding: 0,
	}),
	menu: (provided) => ({
		...provided,
		textAlign: 'start',
		padding: 0,
	}),
	menuList: (provided) => ({
		...provided,
		direction: 'rtl',
		padding: 0,
	}),
	placeholder: (provided) => ({
		...provided,
		color: 'rgb(147, 221, 255)',
		direction: 'rtl', // Ensures placeholder text direction is right-to-left
		padding: 0,
	}),
	input: (provided) => ({
		...provided,
		color: 'white',
		direction: 'rtl',
		margin: '0px',
		padding: 0,
	}),
	singleValue: (provided) => ({
		...provided,
		color: 'white',
		direction: 'rtl',
		padding: 0,
	}),
	valueContainer: (provided) => ({
		...provided,
		padding: '0px', // Remove padding inside the value container where the input and placeholder/selected value are
	}),
	// Add other styles if needed
};

const MobileStyles = {
	control: (provided, state) => ({
		...provided,
		width: 'calc(100vw * (310 / 390))',
		height: 'calc(100vw * (53 / 390)) !important',
		borderRadius: 'calc(100vw * (19 / 390))',
		border: 'calc(100vw * (2 / 390)) solid #00C4FF',
		background: 'linear-gradient(to bottom, rgba(12, 38, 57, 1) 0%, rgba(12, 38, 57, 0.8) 75%, rgba(12, 38, 57, 0) 100%)',
		fontWeight: 'bold',
		fontSize: 'calc(100vw * (20 / 390))',
		color: 'white',
		fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
		padding: '0px calc(100vw * (20 / 390))',
		boxShadow: 'none', // Ensures no boxShadow at all times
		boxSizing: 'border-box',
		borderColor: state.isFocused ? '#00C4FF' : provided.borderColor,
		'&:hover': {
			borderColor: '#00C4FF', // Border color on hover
		},
		direction: 'rtl', // Ensures text direction is always right-to-left
	}),
	internalElement: (provided) => ({
		...provided,
		height: 'auto', // or a specific height that fits your design
		overflow: 'hidden', // if you want to cut off the excess
	}),
	dropdownIndicator: (base) => ({
		...base,
		display: 'none',
		padding: 0,
	}),
	indicatorSeparator: (base) => ({
		...base,
		display: 'none',
		padding: 0,
	}),
	menu: (provided) => ({
		...provided,
		textAlign: 'start',
		padding: 0,
	}),
	menuList: (provided) => ({
		...provided,
		direction: 'rtl',
		padding: 0,
	}),
	placeholder: (provided) => ({
		...provided,
		color: 'rgb(147, 221, 255)',
		direction: 'rtl', // Ensures placeholder text direction is right-to-left
		padding: 0,
		
	}),
	input: (provided) => ({
		...provided,
		color: 'white',
		direction: 'rtl',
		margin: 'calc(100vw * (0 / 390))',
		padding: 0,
	}),
	singleValue: (provided) => ({
		...provided,
		color: 'white',
		direction: 'rtl',
		padding: 0,
	}),
	valueContainer: (provided) => ({
		...provided,
		padding: '0px', // Remove padding inside the value container where the input and placeholder/selected value are
	}),
	// Add other styles if needed
};
