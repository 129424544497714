import React, { useState, useEffect } from "react";
import styles from "./TrueFalse.module.css";

const GameTimer = ({ onEnd, minutes = 1 }) => {
  const [time, setTime] = useState(minutes * 60 - 1);
  const [formattedTime, setFormattedTime] = useState("01:00");

  useEffect(() => {
    const timer = setInterval(() => {
      if (time > 0) {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        const formattedMinutes = minutes.toString().padStart(2, "0");
        const formattedSeconds = seconds.toString().padStart(2, "0");
        setFormattedTime(`${formattedMinutes}:${formattedSeconds}`);
        setTime(time - 1);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [time]);

  useEffect(() => {
    if (time === 0) {
      onEnd();
    }
  }, [time]);

  return (
    <div className={styles.GameTimer}>
      <div className={styles.TimeText}>{formattedTime}</div>
    </div>
  );
};

export default GameTimer;
