export const questions = [
    {
      tf: [
        // Izz Ad-Din Al-Qassam
        { id: 1, en: "", ar: "استشهد عزالدين القسام خلال مواجهة مع قوات الاحتلال البريطاني في فلسطين", correctAns: true },
        { id: 2, en: "", ar: `استُلهمت حركة حماس لتسمية كتائبها العسكرية باسم "كتائب القسام" نسبةً إلى عزالدين القسام`, correctAns: true },
        { id: 3, en: "", ar: "قبل نضاله في فلسطين، نشط عزالدين القسام في سوريا مقاومًا الاستعمار الفرنسي", correctAns: true },
        { id: 4, en: "", ar: "كان عزالدين القسام من المؤسسين الأساسيين لحماس", correctAns: false },
        { id: 5, en: "", ar: "كان عزالدين القسام قائدًا روحيًا للمقاومة الفلسطينية ضد الاستعمار البريطاني وهجرة اليهود الصهاينة إلى فلسطين", correctAns: true },
        { id: 6, en: "", ar: `"وإنه لجهاد نصر أو استشهاد"، هل هذه المقولة منسوبة للشهيد عزالدين القسام`, correctAns: true },

        // Yahya Ayyash
        { id: 7, en: "", ar: `يحيى عياش، المعروف بلقب "مهندس الانتفاضة"، كان مسؤولًا عن تخطيط وتنفيذ هجمات ضد القوات الإسرائيلية`, correctAns: true },
        { id: 8, en: "", ar: `تم اغتيال يحيى عياش من قبل الموساد الإسرائيلي عبر تفجير هاتفه المحمول`, correctAns: true },
        { id: 9, en: "", ar: `كان يحيى عياش من بين المؤسسين الأساسيين لحركة حماس`, correctAns: false },
        { id: 10, en: "", ar: `يحيى عياش، الذي عُرف ببراعته في التخطيط للهجمات، شغل منصب وزير الدفاع في السلطة الوطنية الفلسطينية`, correctAns: false },
        { id: 11, en: "", ar: `هل استخدمت الموساد الإسرائيلي طريقة تفجير هاتف خلوي لاغتيال يحيى عياش`, correctAns: true },
        
        // Abd al-Qader al-Husseini
        { id: 12, en: "", ar: `خلال فترة الصراع بين العرب واليهود في عام 1948، كان عبدالقادر الحسيني زعيمًا بارزًا للقوات الفلسطينية`, correctAns: true },
        { id: 13, en: "", ar: `استشهد عبدالقادر الحسيني في معركة القسطل، التي كانت جزءًا من حروب النكبة`, correctAns: true },
        { id: 14, en: "", ar: `كان عبدالقادر الحسيني من القادة المشهورين في جيش الإنقاذ العربي، الذي تم تشكيله لمواجهة الهجوم الصهيوني`, correctAns: true },
        { id: 15, en: "", ar: `تم تأسيس الهلال الأحمر الفلسطيني بفضل مبادرة من عبدالقادر الحسيني`, correctAns: true },
        { id: 16, en: "", ar: `هل شغل عبدالقادر الحسيني منصب رئيس السلطة الوطنية الفلسطينية في أي وقت من الأوقات`, correctAns: false },
        
        // Naji al-Ali
        { id: 17, en: "", ar: `الفنان ناجي العلي الذي اشتهر برسوماته المعبرة عن القضية الفلسطينية، هو رسّام شخصية الكاريكاتير المشهورة "حنظلة"`, correctAns: true },
        { id: 18, en: "", ar: `تم اغتيال ناجي العلي - رسّام شخصية الكاريكاتير المشهورة "حنظلة" -  في لندن البريطانية بعد مسيرة فنية طويلة`, correctAns: true },
        { id: 19, en: "", ar: `شخصية حنظلة التي أبدعها ناجي العلي، تمثل الطفل الفلسطيني الذي لا يكبر ويظل شاهدًا على معاناة شعبه`, correctAns: true },
        
        // Mohammed Deif
        { id: 20, en: "", ar: `يُعتبر محمد الضيف القائد العسكري لكتائب القسام ومن الذين قادوا عدة عمليات ضد إسرائيل`, correctAns: true },
        { id: 21, en: "", ar: `تعرض محمد الضيف - القائد العسكري - لكتائب القسام لمحاولات مكررة من إسرائيل لاغتياله`, correctAns: true },
        { id: 22, en: "", ar: `شارك محمد الضيف - القائد العسكري لكتائب القسام - بشكل مباشر في تأسيس كتائب عز الدين القسام`, correctAns: false },
        
        // Sheikh Ahmed Yassin
        { id: 23, en: "", ar: `الشيخ أحمد ياسين - الذي اشتهر بدوره القيادي - هو أحد المؤسسين الأوائل لحركة حماس`, correctAns: true },
        { id: 24, en: "", ar: `في محاولة إسرائيل للقضاء على قيادات حماس، تم اغتيال الشيخ أحمد ياسين عبر غارة جوية أثناء خروجه من المسجد`, correctAns: true },
        { id: 25, en: "", ar: `شيخ أحمد ياسين - أحد قيادات ومؤسسي حماس - كان يُعتبر الرئيس الأول للسلطة الوطنية الفلسطينية`, correctAns: false },
        
        // Abu Ubaida
        { id: 26, en: "", ar: `أبوعبيدة، المعروف في الأوساط الفلسطينية، يُعتبر المتحدث الرسمي باسم كتائب القسام`, correctAns: true },
        { id: 27, en: "", ar: `رغم التكتم على هويات النشطاء، تم الكشف عن هوية أبوعبيدة علنياً والتعرف على وجهه`, correctAns: false },
        { id: 28, en: "", ar: `نظرًا لدوره المعروف، يُعتبر أبوعبيدة هو المؤسس الرئيسي لكتائب القسام`, correctAns: false },
        { id: 29, en: "", ar: `في إطار دعم المقاومة الفلسطينية، ألقى أبوعبيدة العديد من الخطب والبيانات التي تمجد المقاومة وأعمالها`, correctAns: true },
        
        // Abdel-Aziz al-Rantisi
        { id: 30, en: "", ar: `بعد وفاة شيخ أحمد ياسين، تولى عبدالعزيز الرنتيسي دورًا قياديًا بارزًا في حركة حماس، خاصة في قطاع غزة`, correctAns: true },
        { id: 31, en: "", ar: `في سياق حياته المهنية، كان عبد العزيز الرنتيسي - أحد قيادات حماس -  يعمل كطبيب متخصص`, correctAns: true },
        { id: 32, en: "", ar: `بالنظر لخبراته التعليمية، عمل عبدالعزيز الرنتيسي - أحد قيادات حماس - كأستاذ جامعي في جامعة القاهرة`, correctAns: false },
        { id: 33, en: "", ar: `ضمن الاغتيالات الإسرائيلية، تم اغتيال عبد العزيز الرنتيسي بصاروخين من طائرة مروحية أثناء تواجده في سيارته`, correctAns: true },
        
        // Shireen Abu Aqla
        { id: 34, en: "", ar: `خلال المواجهات والمظاهرات، كانت شيرين من أولى الصحفيات التي قامت بتغطية مسيرات العودة على حدود فلسطين`, correctAns: true },
        { id: 35, en: "", ar: `من بين الجوائز المرموقة، حصلت شيرين أبو عاقلة - الصحفية الشجاعة - على جائزة نوبل للسلام`, correctAns: false },
        { id: 36, en: "", ar: `تم اغتيال الصحفية شيرين أبو عاقلة من قبل قناص إسرائيلي أثناء تغطيتها أثناء تغطيتها الهجوم الإسرائيلي على جنين`, correctAns: true },
        
        // Yaser Murtaja
        { id: 36, en: "", ar: `تم إغتيال ياسر مرتجى بواسطة قوات الاحتلال الإسرائيلي أثناء تأدية واجبه الصحفي في  تغطية مسيرة العودة الكبرى`, correctAns: true },
      ],
      select: [
        // Izz ad-Din al-Qassam
        {
          id: 1,
          en: "",
          ar: "كيف استشهد عزالدين القسام؟",
          choices: [
            { id: 1, en: "", ar: "خلال مواجهة مع قوات الاحتلال الإسرائيلي", isCorrect: false },
            { id: 2, en: "", ar: "خلال مواجهة مع قوات الاحتلال البريطاني في فلسطين", isCorrect: true },
            { id: 3, en: "", ar: "في انفجار عرضي", isCorrect: false },
            { id: 4, en: "", ar: "بسبب مرض مفاجئ", isCorrect: false },
          ],
        },
        {
          id: 2,
          en: "",
          ar: `بماذا استُلهمت حركة حماس لتسمية كتائبها العسكرية باسم "كتائب القسام"؟`,
          choices: [
            { id: 1, en: "", ar: "نسبةً إلى مؤسس حماس", isCorrect: false },
            { id: 2, en: "", ar: "نسبةً إلى أحد قادة الثورة الفلسطينية", isCorrect: false },
            { id: 3, en: "", ar: "نسبةً إلى عزالدين القسام", isCorrect: true },
            { id: 4, en: "", ar: "نسبةً إلى المدينة المقدسة", isCorrect: false },
          ],
        },
        {
          id: 3,
          en: "",
          ar: `أين نشط عزالدين القسام قبل فلسطين؟`,
          choices: [
            { id: 1, en: "", ar: "في لبنان", isCorrect: false },
            { id: 2, en: "", ar: "في الأردن", isCorrect: false },
            { id: 3, en: "", ar: "في سوريا مقاومًا الاستعمار الفرنسي", isCorrect: true },
            { id: 4, en: "", ar: "في مصر", isCorrect: false },
          ],
        },
        {
          id: 4,
          en: "",
          ar: `هل كان الشهيد عزالدين القسام من المؤسسين الأساسيين لحماس؟`,
          choices: [
            { id: 1, en: "", ar: "نعم", isCorrect: false },
            { id: 2, en: "", ar: "لا", isCorrect: true },
            { id: 3, en: "", ar: "لا يمكن التأكيد", isCorrect: false },
            { id: 4, en: "", ar: "كان من المؤسسين الثانويين", isCorrect: false },
          ],
        },
        {
          id: 5,
          en: "",
          ar: `لمن تنسب المقولة "وإنه لجهاد نصر أو استشهاد" التي يرددها أبو عبيدة؟`,
          choices: [
            { id: 1, en: "", ar: "الشيخ أحمد ياسين", isCorrect: false },
            { id: 2, en: "", ar: "الشهيد عزالدين القسام", isCorrect: true },
            { id: 3, en: "", ar: "صلاح شحادة", isCorrect: false },
            { id: 4, en: "", ar: "ياسر عرفات", isCorrect: false },
          ],
        },
        
        // Yahya Ayyash
        {
          id: 6,
          en: "",
          ar: `ما اللقب الذي كان يُطلق على يحيى عياش؟`,
          choices: [
            { id: 1, en: "", ar: "ملك الانتفاضة", isCorrect: false },
            { id: 2, en: "", ar: "أسد فلسطين", isCorrect: false },
            { id: 3, en: "", ar: "مهندس الانتفاضة", isCorrect: true },
            { id: 4, en: "", ar: "فارس الجهاد", isCorrect: false },
          ],
        },
        {
          id: 7,
          en: "",
          ar: `كيف تم اغتيال المهندس الشهيد يحيى عياش؟`,
          choices: [
            { id: 1, en: "", ar: "بواسطة قناصة", isCorrect: false },
            { id: 2, en: "", ar: "عبر تفجير هاتفه المحمول", isCorrect: true },
            { id: 3, en: "", ar: "بواسطة طائرة بدون طيار", isCorrect: false },
            { id: 4, en: "", ar: "بواسطة متفجرات في منزله", isCorrect: false },
          ],
        },
        {
          id: 8,
          en: "",
          ar: `هل كان يحيى عياش من بين المؤسسين الأساسيين لحماس؟`,
          choices: [
            { id: 1, en: "", ar: "نعم", isCorrect: false },
            { id: 2, en: "", ar: "لا", isCorrect: true },
            { id: 3, en: "", ar: "لا يمكن التأكيد", isCorrect: false },
            { id: 4, en: "", ar: "كان من المؤسسين الثانويين", isCorrect: false },
          ],
        },

        // Abd al-Qader al-Husseini
        {
          id: 8,
          en: "",
          ar: `هل كان يحيى عياش من بين المؤسسين الأساسيين لحماس؟`,
          choices: [
            { id: 1, en: "", ar: "نعم", isCorrect: false },
            { id: 2, en: "", ar: "لا", isCorrect: true },
            { id: 3, en: "", ar: "لا يمكن التأكيد", isCorrect: false },
            { id: 4, en: "", ar: "كان من المؤسسين الثانويين", isCorrect: false },
          ],
        },
        {
          id: 9,
          en: "",
          ar: `في أي جيش كان عبدالقادر الحسيني من القادة المشهورين؟`,
          choices: [
            { id: 1, en: "", ar: "جيش الدفاع العربي", isCorrect: false },
            { id: 2, en: "", ar: "جيش الإنقاذ العربي", isCorrect: true },
            { id: 3, en: "", ar: "جيش التحرير العربي", isCorrect: false },
            { id: 4, en: "", ar: "جيش الفداء", isCorrect: false },
          ],
        },
        {
          id: 10,
          en: "",
          ar: `من هو مؤسس الهلال الأحمر الفلسطيني؟`,
          choices: [
            { id: 1, en: "", ar: "ياسر عرفات", isCorrect: false },
            { id: 2, en: "", ar: "الشيخ أحمد ياسين", isCorrect: false },
            { id: 3, en: "", ar: "عبدالقادر الحسيني", isCorrect: true },
            { id: 4, en: "", ar: "سليم الحصري", isCorrect: false },
          ],
        },

        // Naji al-Ali
        {
          id: 11,
          en: "",
          ar: `من هو الفنان المشهور الذي اشتهر برسم شخصية الكاريكاتير "حنظلة"؟`,
          choices: [
            { id: 1, en: "", ar: "ناجي العلي", isCorrect: true },
            { id: 2, en: "", ar: "غسان كنفاني", isCorrect: false },
            { id: 3, en: "", ar: "محمود درويش", isCorrect: false },
            { id: 4, en: "", ar: "صلاح جاهين", isCorrect: false },
          ],
        },
        {
          id: 12,
          en: "",
          ar: `في أي مدينة تم اغتيال الفنان ناجي العلي الذي اشتهر برسم شخصية الكاريكاتير "حنظلة"؟`,
          choices: [
            { id: 1, en: "", ar: "بيروت", isCorrect: false },
            { id: 2, en: "", ar: "دمشق", isCorrect: false },
            { id: 3, en: "", ar: "جدة", isCorrect: false },
            { id: 4, en: "", ar: "لندن", isCorrect: true },
          ],
        },
        {
          id: 13,
          en: "",
          ar: `ماذا تمثل شخصية حنظلة الفلسطينية التي أبدعها ناجي العلي؟`,
          choices: [
            { id: 1, en: "", ar: "الشهيد الفلسطيني", isCorrect: false },
            { id: 2, en: "", ar: "الطفل الذي لا يكبر", isCorrect: true },
            { id: 3, en: "", ar: "الثوار العرب", isCorrect: false },
            { id: 4, en: "", ar: "الحرية العربية", isCorrect: false },
          ],
        },

        // Mohammed Deif
        {
          id: 14,
          en: "",
          ar: `من هو القائد العسكري لكتائب القسام؟`,
          choices: [
            { id: 1, en: "", ar: "محمد الضيف", isCorrect: true },
            { id: 2, en: "", ar: "عز الدين القسام", isCorrect: false },
            { id: 3, en: "", ar: "خالد مشعل", isCorrect: false },
            { id: 4, en: "", ar: "مروان برغوثي", isCorrect: false },
          ],
        },
        {
          id: 15,
          en: "",
          ar: `هل تعرض محمد الضيف لمحاولات اغتيال من قبل إسرائيل؟`,
          choices: [
            { id: 1, en: "", ar: "نعم، مرة واحدة", isCorrect: false },
            { id: 2, en: "", ar: "لا", isCorrect: false },
            { id: 3, en: "", ar: "نعم، محاولات مكررة", isCorrect: true },
            { id: 4, en: "", ar: "لا يمكن التأكيد", isCorrect: false },
          ],
        },
        {
          id: 16,
          en: "",
          ar: `هل شارك محمد الضيف بشكل مباشر في تأسيس كتائب عز الدين القسام؟`,
          choices: [
            { id: 1, en: "", ar: "نعم، مرة واحدة", isCorrect: false },
            { id: 2, en: "", ar: "لا", isCorrect: true },
            { id: 3, en: "", ar: "شارك بشكل غير مباشر", isCorrect: false },
            { id: 4, en: "", ar: "كان من المؤسسين الثانويين", isCorrect: false },
          ],
        },

        // Sheikh Ahmed Yassin
        {
          id: 17,
          en: "",
          ar: `أحد الخيارات التالية هو أحد المؤسسين الأوائل لحركة حماس؟`,
          choices: [
            { id: 1, en: "", ar: "ياسر عرفات", isCorrect: false },
            { id: 2, en: "", ar: "مروان برغوثي", isCorrect: false },
            { id: 3, en: "", ar: "الشيخ أحمد ياسين", isCorrect: true },
            { id: 4, en: "", ar: "خالد مشعل", isCorrect: false },
          ],
        },
        {
          id: 18,
          en: "",
          ar: `كيف تم اغتيال الشيخ أحمد ياسين، أحد المؤسسين الأوائل لحركة حماس؟`,
          choices: [
            { id: 1, en: "", ar: "في هجوم بري", isCorrect: false },
            { id: 2, en: "", ar: "بغارة جوية بعد صلاة الفجر", isCorrect: true },
            { id: 3, en: "", ar: "في مواجهة مباشرة", isCorrect: false },
            { id: 4, en: "", ar: "بواسطة قناص", isCorrect: false },
          ],
        },

        // Abu Ubaida
        {
          id: 19,
          en: "",
          ar: `من هو المتحدث الرسمي باسم كتائب القسام؟`,
          choices: [
            { id: 1, en: "", ar: "أبو مازن", isCorrect: false },
            { id: 2, en: "", ar: "أبو عبيدة", isCorrect: true },
            { id: 3, en: "", ar: "أبو العباس", isCorrect: false },
            { id: 4, en: "", ar: "أبو نضال", isCorrect: false },
          ],
        },
        {
          id: 20,
          en: "",
          ar: `هل تم الكشف عن هوية أبوعبيدة - الناطق الرسمي باسم كتائب القسام - علنياً؟`,
          choices: [
            { id: 1, en: "", ar: "نعم يعرف الجميع وجهه", isCorrect: false },
            { id: 2, en: "", ar: "تم كشف هويته فقط", isCorrect: false },
            { id: 3, en: "", ar: "لا يزال مجهول الهوية", isCorrect: true },
            { id: 4, en: "", ar: "بعض المعلومات فقط", isCorrect: false },
          ],
        },
        {
          id: 21,
          en: "",
          ar: `هل ألقى أبوعبيدة - الناطق الرسمي باسم كتائب القسام - خطبًا وبيانات تمجد المقاومة الفلسطينية؟`,
          choices: [
            { id: 1, en: "", ar: "نعم، العديد منها", isCorrect: true },
            { id: 2, en: "", ar: "نعم، ولكن نادرًا", isCorrect: false },
            { id: 3, en: "", ar: "لم يظهر علنيًا أبدًا", isCorrect: false },
            { id: 4, en: "", ar: "كان يتحدث فقط عن السلام", isCorrect: false },
          ],
        },

        // Abdel-Aziz al-Rantisi
        {
          id: 22,
          en: "",
          ar: `من الرجل الذي تولى دورًا قياديًا بارزًا في حركة حماس بعد وفاة شيخ أحمد ياسين؟`,
          choices: [
            { id: 1, en: "", ar: "ياسر عرفات", isCorrect: false },
            { id: 2, en: "", ar: "عبدالعزيز الرنتيسي", isCorrect: true },
            { id: 3, en: "", ar: "محمد الضيف", isCorrect: false },
            { id: 4, en: "", ar: "خالد مشعل", isCorrect: false },
          ],
        },
        {
          id: 23,
          en: "",
          ar: `ما كانت مهنة عبد العزيز الرنتيسي - أحد قادات حماس -  قبل دخوله العمل السياسي؟`,
          choices: [
            { id: 1, en: "", ar: "مهندس", isCorrect: false },
            { id: 2, en: "", ar: "محامي", isCorrect: false },
            { id: 3, en: "", ar: "طبيب", isCorrect: true },
            { id: 4, en: "", ar: "معلم", isCorrect: false },
          ],
        },
        {
          id: 24,
          en: "",
          ar: `كيف تم استهداف عبد العزيز الرنتيسي، أحد قادات حماس؟`,
          choices: [
            { id: 1, en: "", ar: "بقنبلة على منزله", isCorrect: false },
            { id: 2, en: "", ar: "بصاروخين من طائرة مروحية", isCorrect: true },
            { id: 3, en: "", ar: "بواسطة قناص", isCorrect: false },
            { id: 4, en: "", ar: "في مواجهة مباشرة", isCorrect: false },
          ],
        },

        // Shireen Abu Aqla
        {
          id: 25,
          en: "",
          ar: `ما هي الجائزة التي حصلت عليها الصحفية الشجاعة شيرين أبو عاقلة؟`,
          choices: [
            { id: 1, en: "", ar: "جائزة نوبل للسلام", isCorrect: false },
            { id: 2, en: "", ar: "جائزة الصحافة العالمية", isCorrect: false },
            { id: 3, en: "", ar: "جائزة بوليتزر", isCorrect: false },
            { id: 4, en: "", ar: "لم تحصل على أي جائزة", isCorrect: true },
          ],
        },
        {
          id: 26,
          en: "",
          ar: `كيف توفيت الصحفية شيرين أبو عاقلة أثناء تغطيتها الهجوم الإسرائيلي على جنين؟`,
          choices: [
            { id: 1, en: "", ar: "في حادث سير", isCorrect: false },
            { id: 2, en: "", ar: "من قبل قناص إسرائيلي", isCorrect: true },
            { id: 3, en: "", ar: "بطريقة طبيعية", isCorrect: false },
            { id: 4, en: "", ar: "لم تتوفى", isCorrect: false },
          ],
        },
        {
          id: 27,
          en: "",
          ar: `كيف توفي الصحفي  ياسر مرتجى أثناء تأدية واجبه الصحفي في تغطية مسيرة العودة الكبرى؟`,
          choices: [
            { id: 1, en: "", ar: "بواسطة قوات الاحتلال", isCorrect: true },
            { id: 2, en: "", ar: "في حادث سير", isCorrect: false },
            { id: 3, en: "", ar: "بطريقة طبيعية", isCorrect: false },
            { id: 4, en: "", ar: "في هجوم بري", isCorrect: false },
          ],
        },

      ],
    },
    {
      tf: [
        // Izz Ad-Din Al-Qassam
        { id: 1, en: "", ar: "استشهد عزالدين القسام خلال مواجهة مع قوات الاحتلال البريطاني في فلسطين", correctAns: true },
        { id: 2, en: "", ar: `استُلهمت حركة حماس لتسمية كتائبها العسكرية باسم "كتائب القسام" نسبةً إلى عزالدين القسام`, correctAns: true },
        { id: 3, en: "", ar: "قبل نضاله في فلسطين، نشط عزالدين القسام في سوريا مقاومًا الاستعمار الفرنسي", correctAns: true },
        { id: 4, en: "", ar: "كان عزالدين القسام من المؤسسين الأساسيين لحماس", correctAns: false },
        { id: 5, en: "", ar: "كان عزالدين القسام قائدًا روحيًا للمقاومة الفلسطينية ضد الاستعمار البريطاني وهجرة اليهود الصهاينة إلى فلسطين", correctAns: true },
        { id: 6, en: "", ar: `"وإنه لجهاد نصر أو استشهاد"، هل هذه المقولة منسوبة للشهيد عزالدين القسام`, correctAns: true },

        // Yahya Ayyash
        { id: 7, en: "", ar: `يحيى عياش، المعروف بلقب "مهندس الانتفاضة"، كان مسؤولًا عن تخطيط وتنفيذ هجمات ضد القوات الإسرائيلية`, correctAns: true },
        { id: 8, en: "", ar: `تم اغتيال يحيى عياش من قبل الموساد الإسرائيلي عبر تفجير هاتفه المحمول`, correctAns: true },
        { id: 9, en: "", ar: `كان يحيى عياش من بين المؤسسين الأساسيين لحركة حماس`, correctAns: false },
        { id: 10, en: "", ar: `يحيى عياش، الذي عُرف ببراعته في التخطيط للهجمات، شغل منصب وزير الدفاع في السلطة الوطنية الفلسطينية`, correctAns: false },
        { id: 11, en: "", ar: `هل استخدمت الموساد الإسرائيلي طريقة تفجير هاتف خلوي لاغتيال يحيى عياش`, correctAns: true },
        
        // Abd al-Qader al-Husseini
        { id: 12, en: "", ar: `خلال فترة الصراع بين العرب واليهود في عام 1948، كان عبدالقادر الحسيني زعيمًا بارزًا للقوات الفلسطينية`, correctAns: true },
        { id: 13, en: "", ar: `استشهد عبدالقادر الحسيني في معركة القسطل، التي كانت جزءًا من حروب النكبة`, correctAns: true },
        { id: 14, en: "", ar: `كان عبدالقادر الحسيني من القادة المشهورين في جيش الإنقاذ العربي، الذي تم تشكيله لمواجهة الهجوم الصهيوني`, correctAns: true },
        { id: 15, en: "", ar: `تم تأسيس الهلال الأحمر الفلسطيني بفضل مبادرة من عبدالقادر الحسيني`, correctAns: true },
        { id: 16, en: "", ar: `هل شغل عبدالقادر الحسيني منصب رئيس السلطة الوطنية الفلسطينية في أي وقت من الأوقات`, correctAns: false },
        
        // Naji al-Ali
        { id: 17, en: "", ar: `الفنان ناجي العلي الذي اشتهر برسوماته المعبرة عن القضية الفلسطينية، هو رسّام شخصية الكاريكاتير المشهورة "حنظلة"`, correctAns: true },
        { id: 18, en: "", ar: `تم اغتيال ناجي العلي - رسّام شخصية الكاريكاتير المشهورة "حنظلة" -  في لندن البريطانية بعد مسيرة فنية طويلة`, correctAns: true },
        { id: 19, en: "", ar: `شخصية حنظلة التي أبدعها ناجي العلي، تمثل الطفل الفلسطيني الذي لا يكبر ويظل شاهدًا على معاناة شعبه`, correctAns: true },
        
        // Mohammed Deif
        { id: 20, en: "", ar: `يُعتبر محمد الضيف القائد العسكري لكتائب القسام ومن الذين قادوا عدة عمليات ضد إسرائيل`, correctAns: true },
        { id: 21, en: "", ar: `تعرض محمد الضيف - القائد العسكري - لكتائب القسام لمحاولات مكررة من إسرائيل لاغتياله`, correctAns: true },
        { id: 22, en: "", ar: `شارك محمد الضيف - القائد العسكري لكتائب القسام - بشكل مباشر في تأسيس كتائب عز الدين القسام`, correctAns: false },
        
        // Sheikh Ahmed Yassin
        { id: 23, en: "", ar: `الشيخ أحمد ياسين - الذي اشتهر بدوره القيادي - هو أحد المؤسسين الأوائل لحركة حماس`, correctAns: true },
        { id: 24, en: "", ar: `في محاولة إسرائيل للقضاء على قيادات حماس، تم اغتيال الشيخ أحمد ياسين عبر غارة جوية أثناء خروجه من المسجد`, correctAns: true },
        { id: 25, en: "", ar: `شيخ أحمد ياسين - أحد قيادات ومؤسسي حماس - كان يُعتبر الرئيس الأول للسلطة الوطنية الفلسطينية`, correctAns: false },
        
        // Abu Ubaida
        { id: 26, en: "", ar: `أبوعبيدة، المعروف في الأوساط الفلسطينية، يُعتبر المتحدث الرسمي باسم كتائب القسام`, correctAns: true },
        { id: 27, en: "", ar: `رغم التكتم على هويات النشطاء، تم الكشف عن هوية أبوعبيدة علنياً والتعرف على وجهه`, correctAns: false },
        { id: 28, en: "", ar: `نظرًا لدوره المعروف، يُعتبر أبوعبيدة هو المؤسس الرئيسي لكتائب القسام`, correctAns: false },
        { id: 29, en: "", ar: `في إطار دعم المقاومة الفلسطينية، ألقى أبوعبيدة العديد من الخطب والبيانات التي تمجد المقاومة وأعمالها`, correctAns: true },
        
        // Abdel-Aziz al-Rantisi
        { id: 30, en: "", ar: `بعد وفاة شيخ أحمد ياسين، تولى عبدالعزيز الرنتيسي دورًا قياديًا بارزًا في حركة حماس، خاصة في قطاع غزة`, correctAns: true },
        { id: 31, en: "", ar: `في سياق حياته المهنية، كان عبد العزيز الرنتيسي - أحد قيادات حماس -  يعمل كطبيب متخصص`, correctAns: true },
        { id: 32, en: "", ar: `بالنظر لخبراته التعليمية، عمل عبدالعزيز الرنتيسي - أحد قيادات حماس - كأستاذ جامعي في جامعة القاهرة`, correctAns: false },
        { id: 33, en: "", ar: `ضمن الاغتيالات الإسرائيلية، تم اغتيال عبد العزيز الرنتيسي بصاروخين من طائرة مروحية أثناء تواجده في سيارته`, correctAns: true },
        
        // Shireen Abu Aqla
        { id: 34, en: "", ar: `خلال المواجهات والمظاهرات، كانت شيرين من أولى الصحفيات التي قامت بتغطية مسيرات العودة على حدود فلسطين`, correctAns: true },
        { id: 35, en: "", ar: `من بين الجوائز المرموقة، حصلت شيرين أبو عاقلة - الصحفية الشجاعة - على جائزة نوبل للسلام`, correctAns: false },
        { id: 36, en: "", ar: `تم اغتيال الصحفية شيرين أبو عاقلة من قبل قناص إسرائيلي أثناء تغطيتها أثناء تغطيتها الهجوم الإسرائيلي على جنين`, correctAns: true },
        
        // Yaser Murtaja
        { id: 36, en: "", ar: `تم إغتيال ياسر مرتجى بواسطة قوات الاحتلال الإسرائيلي أثناء تأدية واجبه الصحفي في  تغطية مسيرة العودة الكبرى`, correctAns: true },
      ],
      select: [
        // Izz ad-Din al-Qassam
        {
          id: 1,
          en: "",
          ar: "كيف استشهد عزالدين القسام؟",
          choices: [
            { id: 1, en: "", ar: "خلال مواجهة مع قوات الاحتلال الإسرائيلي", isCorrect: false },
            { id: 2, en: "", ar: "خلال مواجهة مع قوات الاحتلال البريطاني في فلسطين", isCorrect: true },
            { id: 3, en: "", ar: "في انفجار عرضي", isCorrect: false },
            { id: 4, en: "", ar: "بسبب مرض مفاجئ", isCorrect: false },
          ],
        },
        {
          id: 2,
          en: "",
          ar: `بماذا استُلهمت حركة حماس لتسمية كتائبها العسكرية باسم "كتائب القسام"؟`,
          choices: [
            { id: 1, en: "", ar: "نسبةً إلى مؤسس حماس", isCorrect: false },
            { id: 2, en: "", ar: "نسبةً إلى أحد قادة الثورة الفلسطينية", isCorrect: false },
            { id: 3, en: "", ar: "نسبةً إلى عزالدين القسام", isCorrect: true },
            { id: 4, en: "", ar: "نسبةً إلى المدينة المقدسة", isCorrect: false },
          ],
        },
        {
          id: 3,
          en: "",
          ar: `أين نشط عزالدين القسام قبل فلسطين؟`,
          choices: [
            { id: 1, en: "", ar: "في لبنان", isCorrect: false },
            { id: 2, en: "", ar: "في الأردن", isCorrect: false },
            { id: 3, en: "", ar: "في سوريا مقاومًا الاستعمار الفرنسي", isCorrect: true },
            { id: 4, en: "", ar: "في مصر", isCorrect: false },
          ],
        },
        {
          id: 4,
          en: "",
          ar: `هل كان الشهيد عزالدين القسام من المؤسسين الأساسيين لحماس؟`,
          choices: [
            { id: 1, en: "", ar: "نعم", isCorrect: false },
            { id: 2, en: "", ar: "لا", isCorrect: true },
            { id: 3, en: "", ar: "لا يمكن التأكيد", isCorrect: false },
            { id: 4, en: "", ar: "كان من المؤسسين الثانويين", isCorrect: false },
          ],
        },
        {
          id: 5,
          en: "",
          ar: `لمن تنسب المقولة "وإنه لجهاد نصر أو استشهاد" التي يرددها أبو عبيدة؟`,
          choices: [
            { id: 1, en: "", ar: "الشيخ أحمد ياسين", isCorrect: false },
            { id: 2, en: "", ar: "الشهيد عزالدين القسام", isCorrect: true },
            { id: 3, en: "", ar: "صلاح شحادة", isCorrect: false },
            { id: 4, en: "", ar: "ياسر عرفات", isCorrect: false },
          ],
        },
        
        // Yahya Ayyash
        {
          id: 6,
          en: "",
          ar: `ما اللقب الذي كان يُطلق على يحيى عياش؟`,
          choices: [
            { id: 1, en: "", ar: "ملك الانتفاضة", isCorrect: false },
            { id: 2, en: "", ar: "أسد فلسطين", isCorrect: false },
            { id: 3, en: "", ar: "مهندس الانتفاضة", isCorrect: true },
            { id: 4, en: "", ar: "فارس الجهاد", isCorrect: false },
          ],
        },
        {
          id: 7,
          en: "",
          ar: `كيف تم اغتيال المهندس الشهيد يحيى عياش؟`,
          choices: [
            { id: 1, en: "", ar: "بواسطة قناصة", isCorrect: false },
            { id: 2, en: "", ar: "عبر تفجير هاتفه المحمول", isCorrect: true },
            { id: 3, en: "", ar: "بواسطة طائرة بدون طيار", isCorrect: false },
            { id: 4, en: "", ar: "بواسطة متفجرات في منزله", isCorrect: false },
          ],
        },
        {
          id: 8,
          en: "",
          ar: `هل كان يحيى عياش من بين المؤسسين الأساسيين لحماس؟`,
          choices: [
            { id: 1, en: "", ar: "نعم", isCorrect: false },
            { id: 2, en: "", ar: "لا", isCorrect: true },
            { id: 3, en: "", ar: "لا يمكن التأكيد", isCorrect: false },
            { id: 4, en: "", ar: "كان من المؤسسين الثانويين", isCorrect: false },
          ],
        },

        // Abd al-Qader al-Husseini
        {
          id: 8,
          en: "",
          ar: `هل كان يحيى عياش من بين المؤسسين الأساسيين لحماس؟`,
          choices: [
            { id: 1, en: "", ar: "نعم", isCorrect: false },
            { id: 2, en: "", ar: "لا", isCorrect: true },
            { id: 3, en: "", ar: "لا يمكن التأكيد", isCorrect: false },
            { id: 4, en: "", ar: "كان من المؤسسين الثانويين", isCorrect: false },
          ],
        },
        {
          id: 9,
          en: "",
          ar: `في أي جيش كان عبدالقادر الحسيني من القادة المشهورين؟`,
          choices: [
            { id: 1, en: "", ar: "جيش الدفاع العربي", isCorrect: false },
            { id: 2, en: "", ar: "جيش الإنقاذ العربي", isCorrect: true },
            { id: 3, en: "", ar: "جيش التحرير العربي", isCorrect: false },
            { id: 4, en: "", ar: "جيش الفداء", isCorrect: false },
          ],
        },
        {
          id: 10,
          en: "",
          ar: `من هو مؤسس الهلال الأحمر الفلسطيني؟`,
          choices: [
            { id: 1, en: "", ar: "ياسر عرفات", isCorrect: false },
            { id: 2, en: "", ar: "الشيخ أحمد ياسين", isCorrect: false },
            { id: 3, en: "", ar: "عبدالقادر الحسيني", isCorrect: true },
            { id: 4, en: "", ar: "سليم الحصري", isCorrect: false },
          ],
        },

        // Naji al-Ali
        {
          id: 11,
          en: "",
          ar: `من هو الفنان المشهور الذي اشتهر برسم شخصية الكاريكاتير "حنظلة"؟`,
          choices: [
            { id: 1, en: "", ar: "ناجي العلي", isCorrect: true },
            { id: 2, en: "", ar: "غسان كنفاني", isCorrect: false },
            { id: 3, en: "", ar: "محمود درويش", isCorrect: false },
            { id: 4, en: "", ar: "صلاح جاهين", isCorrect: false },
          ],
        },
        {
          id: 12,
          en: "",
          ar: `في أي مدينة تم اغتيال الفنان ناجي العلي الذي اشتهر برسم شخصية الكاريكاتير "حنظلة"؟`,
          choices: [
            { id: 1, en: "", ar: "بيروت", isCorrect: false },
            { id: 2, en: "", ar: "دمشق", isCorrect: false },
            { id: 3, en: "", ar: "جدة", isCorrect: false },
            { id: 4, en: "", ar: "لندن", isCorrect: true },
          ],
        },
        {
          id: 13,
          en: "",
          ar: `ماذا تمثل شخصية حنظلة الفلسطينية التي أبدعها ناجي العلي؟`,
          choices: [
            { id: 1, en: "", ar: "الشهيد الفلسطيني", isCorrect: false },
            { id: 2, en: "", ar: "الطفل الذي لا يكبر", isCorrect: true },
            { id: 3, en: "", ar: "الثوار العرب", isCorrect: false },
            { id: 4, en: "", ar: "الحرية العربية", isCorrect: false },
          ],
        },

        // Mohammed Deif
        {
          id: 14,
          en: "",
          ar: `من هو القائد العسكري لكتائب القسام؟`,
          choices: [
            { id: 1, en: "", ar: "محمد الضيف", isCorrect: true },
            { id: 2, en: "", ar: "عز الدين القسام", isCorrect: false },
            { id: 3, en: "", ar: "خالد مشعل", isCorrect: false },
            { id: 4, en: "", ar: "مروان برغوثي", isCorrect: false },
          ],
        },
        {
          id: 15,
          en: "",
          ar: `هل تعرض محمد الضيف لمحاولات اغتيال من قبل إسرائيل؟`,
          choices: [
            { id: 1, en: "", ar: "نعم، مرة واحدة", isCorrect: false },
            { id: 2, en: "", ar: "لا", isCorrect: false },
            { id: 3, en: "", ar: "نعم، محاولات مكررة", isCorrect: true },
            { id: 4, en: "", ar: "لا يمكن التأكيد", isCorrect: false },
          ],
        },
        {
          id: 16,
          en: "",
          ar: `هل شارك محمد الضيف بشكل مباشر في تأسيس كتائب عز الدين القسام؟`,
          choices: [
            { id: 1, en: "", ar: "نعم، مرة واحدة", isCorrect: false },
            { id: 2, en: "", ar: "لا", isCorrect: true },
            { id: 3, en: "", ar: "شارك بشكل غير مباشر", isCorrect: false },
            { id: 4, en: "", ar: "كان من المؤسسين الثانويين", isCorrect: false },
          ],
        },

        // Sheikh Ahmed Yassin
        {
          id: 17,
          en: "",
          ar: `أحد الخيارات التالية هو أحد المؤسسين الأوائل لحركة حماس؟`,
          choices: [
            { id: 1, en: "", ar: "ياسر عرفات", isCorrect: false },
            { id: 2, en: "", ar: "مروان برغوثي", isCorrect: false },
            { id: 3, en: "", ar: "الشيخ أحمد ياسين", isCorrect: true },
            { id: 4, en: "", ar: "خالد مشعل", isCorrect: false },
          ],
        },
        {
          id: 18,
          en: "",
          ar: `كيف تم اغتيال الشيخ أحمد ياسين، أحد المؤسسين الأوائل لحركة حماس؟`,
          choices: [
            { id: 1, en: "", ar: "في هجوم بري", isCorrect: false },
            { id: 2, en: "", ar: "بغارة جوية بعد صلاة الفجر", isCorrect: true },
            { id: 3, en: "", ar: "في مواجهة مباشرة", isCorrect: false },
            { id: 4, en: "", ar: "بواسطة قناص", isCorrect: false },
          ],
        },

        // Abu Ubaida
        {
          id: 19,
          en: "",
          ar: `من هو المتحدث الرسمي باسم كتائب القسام؟`,
          choices: [
            { id: 1, en: "", ar: "أبو مازن", isCorrect: false },
            { id: 2, en: "", ar: "أبو عبيدة", isCorrect: true },
            { id: 3, en: "", ar: "أبو العباس", isCorrect: false },
            { id: 4, en: "", ar: "أبو نضال", isCorrect: false },
          ],
        },
        {
          id: 20,
          en: "",
          ar: `هل تم الكشف عن هوية أبوعبيدة - الناطق الرسمي باسم كتائب القسام - علنياً؟`,
          choices: [
            { id: 1, en: "", ar: "نعم يعرف الجميع وجهه", isCorrect: false },
            { id: 2, en: "", ar: "تم كشف هويته فقط", isCorrect: false },
            { id: 3, en: "", ar: "لا يزال مجهول الهوية", isCorrect: true },
            { id: 4, en: "", ar: "بعض المعلومات فقط", isCorrect: false },
          ],
        },
        {
          id: 21,
          en: "",
          ar: `هل ألقى أبوعبيدة - الناطق الرسمي باسم كتائب القسام - خطبًا وبيانات تمجد المقاومة الفلسطينية؟`,
          choices: [
            { id: 1, en: "", ar: "نعم، العديد منها", isCorrect: true },
            { id: 2, en: "", ar: "نعم، ولكن نادرًا", isCorrect: false },
            { id: 3, en: "", ar: "لم يظهر علنيًا أبدًا", isCorrect: false },
            { id: 4, en: "", ar: "كان يتحدث فقط عن السلام", isCorrect: false },
          ],
        },

        // Abdel-Aziz al-Rantisi
        {
          id: 22,
          en: "",
          ar: `من الرجل الذي تولى دورًا قياديًا بارزًا في حركة حماس بعد وفاة شيخ أحمد ياسين؟`,
          choices: [
            { id: 1, en: "", ar: "ياسر عرفات", isCorrect: false },
            { id: 2, en: "", ar: "عبدالعزيز الرنتيسي", isCorrect: true },
            { id: 3, en: "", ar: "محمد الضيف", isCorrect: false },
            { id: 4, en: "", ar: "خالد مشعل", isCorrect: false },
          ],
        },
        {
          id: 23,
          en: "",
          ar: `ما كانت مهنة عبد العزيز الرنتيسي - أحد قادات حماس -  قبل دخوله العمل السياسي؟`,
          choices: [
            { id: 1, en: "", ar: "مهندس", isCorrect: false },
            { id: 2, en: "", ar: "محامي", isCorrect: false },
            { id: 3, en: "", ar: "طبيب", isCorrect: true },
            { id: 4, en: "", ar: "معلم", isCorrect: false },
          ],
        },
        {
          id: 24,
          en: "",
          ar: `كيف تم استهداف عبد العزيز الرنتيسي، أحد قادات حماس؟`,
          choices: [
            { id: 1, en: "", ar: "بقنبلة على منزله", isCorrect: false },
            { id: 2, en: "", ar: "بصاروخين من طائرة مروحية", isCorrect: true },
            { id: 3, en: "", ar: "بواسطة قناص", isCorrect: false },
            { id: 4, en: "", ar: "في مواجهة مباشرة", isCorrect: false },
          ],
        },

        // Shireen Abu Aqla
        {
          id: 25,
          en: "",
          ar: `ما هي الجائزة التي حصلت عليها الصحفية الشجاعة شيرين أبو عاقلة؟`,
          choices: [
            { id: 1, en: "", ar: "جائزة نوبل للسلام", isCorrect: false },
            { id: 2, en: "", ar: "جائزة الصحافة العالمية", isCorrect: false },
            { id: 3, en: "", ar: "جائزة بوليتزر", isCorrect: false },
            { id: 4, en: "", ar: "لم تحصل على أي جائزة", isCorrect: true },
          ],
        },
        {
          id: 26,
          en: "",
          ar: `كيف توفيت الصحفية شيرين أبو عاقلة أثناء تغطيتها الهجوم الإسرائيلي على جنين؟`,
          choices: [
            { id: 1, en: "", ar: "في حادث سير", isCorrect: false },
            { id: 2, en: "", ar: "من قبل قناص إسرائيلي", isCorrect: true },
            { id: 3, en: "", ar: "بطريقة طبيعية", isCorrect: false },
            { id: 4, en: "", ar: "لم تتوفى", isCorrect: false },
          ],
        },
        {
          id: 27,
          en: "",
          ar: `كيف توفي الصحفي  ياسر مرتجى أثناء تأدية واجبه الصحفي في تغطية مسيرة العودة الكبرى؟`,
          choices: [
            { id: 1, en: "", ar: "بواسطة قوات الاحتلال", isCorrect: true },
            { id: 2, en: "", ar: "في حادث سير", isCorrect: false },
            { id: 3, en: "", ar: "بطريقة طبيعية", isCorrect: false },
            { id: 4, en: "", ar: "في هجوم بري", isCorrect: false },
          ],
        },

      ],
    },
    {
      tf: [
        // Izz Ad-Din Al-Qassam
        { id: 1, en: "", ar: "استشهد عزالدين القسام خلال مواجهة مع قوات الاحتلال البريطاني في فلسطين", correctAns: true },
        { id: 2, en: "", ar: `استُلهمت حركة حماس لتسمية كتائبها العسكرية باسم "كتائب القسام" نسبةً إلى عزالدين القسام`, correctAns: true },
        { id: 3, en: "", ar: "قبل نضاله في فلسطين، نشط عزالدين القسام في سوريا مقاومًا الاستعمار الفرنسي", correctAns: true },
        { id: 4, en: "", ar: "كان عزالدين القسام من المؤسسين الأساسيين لحماس", correctAns: false },
        { id: 5, en: "", ar: "كان عزالدين القسام قائدًا روحيًا للمقاومة الفلسطينية ضد الاستعمار البريطاني وهجرة اليهود الصهاينة إلى فلسطين", correctAns: true },
        { id: 6, en: "", ar: `"وإنه لجهاد نصر أو استشهاد"، هل هذه المقولة منسوبة للشهيد عزالدين القسام`, correctAns: true },

        // Yahya Ayyash
        { id: 7, en: "", ar: `يحيى عياش، المعروف بلقب "مهندس الانتفاضة"، كان مسؤولًا عن تخطيط وتنفيذ هجمات ضد القوات الإسرائيلية`, correctAns: true },
        { id: 8, en: "", ar: `تم اغتيال يحيى عياش من قبل الموساد الإسرائيلي عبر تفجير هاتفه المحمول`, correctAns: true },
        { id: 9, en: "", ar: `كان يحيى عياش من بين المؤسسين الأساسيين لحركة حماس`, correctAns: false },
        { id: 10, en: "", ar: `يحيى عياش، الذي عُرف ببراعته في التخطيط للهجمات، شغل منصب وزير الدفاع في السلطة الوطنية الفلسطينية`, correctAns: false },
        { id: 11, en: "", ar: `هل استخدمت الموساد الإسرائيلي طريقة تفجير هاتف خلوي لاغتيال يحيى عياش`, correctAns: true },
        
        // Abd al-Qader al-Husseini
        { id: 12, en: "", ar: `خلال فترة الصراع بين العرب واليهود في عام 1948، كان عبدالقادر الحسيني زعيمًا بارزًا للقوات الفلسطينية`, correctAns: true },
        { id: 13, en: "", ar: `استشهد عبدالقادر الحسيني في معركة القسطل، التي كانت جزءًا من حروب النكبة`, correctAns: true },
        { id: 14, en: "", ar: `كان عبدالقادر الحسيني من القادة المشهورين في جيش الإنقاذ العربي، الذي تم تشكيله لمواجهة الهجوم الصهيوني`, correctAns: true },
        { id: 15, en: "", ar: `تم تأسيس الهلال الأحمر الفلسطيني بفضل مبادرة من عبدالقادر الحسيني`, correctAns: true },
        { id: 16, en: "", ar: `هل شغل عبدالقادر الحسيني منصب رئيس السلطة الوطنية الفلسطينية في أي وقت من الأوقات`, correctAns: false },
        
        // Naji al-Ali
        { id: 17, en: "", ar: `الفنان ناجي العلي الذي اشتهر برسوماته المعبرة عن القضية الفلسطينية، هو رسّام شخصية الكاريكاتير المشهورة "حنظلة"`, correctAns: true },
        { id: 18, en: "", ar: `تم اغتيال ناجي العلي - رسّام شخصية الكاريكاتير المشهورة "حنظلة" -  في لندن البريطانية بعد مسيرة فنية طويلة`, correctAns: true },
        { id: 19, en: "", ar: `شخصية حنظلة التي أبدعها ناجي العلي، تمثل الطفل الفلسطيني الذي لا يكبر ويظل شاهدًا على معاناة شعبه`, correctAns: true },
        
        // Mohammed Deif
        { id: 20, en: "", ar: `يُعتبر محمد الضيف القائد العسكري لكتائب القسام ومن الذين قادوا عدة عمليات ضد إسرائيل`, correctAns: true },
        { id: 21, en: "", ar: `تعرض محمد الضيف - القائد العسكري - لكتائب القسام لمحاولات مكررة من إسرائيل لاغتياله`, correctAns: true },
        { id: 22, en: "", ar: `شارك محمد الضيف - القائد العسكري لكتائب القسام - بشكل مباشر في تأسيس كتائب عز الدين القسام`, correctAns: false },
        
        // Sheikh Ahmed Yassin
        { id: 23, en: "", ar: `الشيخ أحمد ياسين - الذي اشتهر بدوره القيادي - هو أحد المؤسسين الأوائل لحركة حماس`, correctAns: true },
        { id: 24, en: "", ar: `في محاولة إسرائيل للقضاء على قيادات حماس، تم اغتيال الشيخ أحمد ياسين عبر غارة جوية أثناء خروجه من المسجد`, correctAns: true },
        { id: 25, en: "", ar: `شيخ أحمد ياسين - أحد قيادات ومؤسسي حماس - كان يُعتبر الرئيس الأول للسلطة الوطنية الفلسطينية`, correctAns: false },
        
        // Abu Ubaida
        { id: 26, en: "", ar: `أبوعبيدة، المعروف في الأوساط الفلسطينية، يُعتبر المتحدث الرسمي باسم كتائب القسام`, correctAns: true },
        { id: 27, en: "", ar: `رغم التكتم على هويات النشطاء، تم الكشف عن هوية أبوعبيدة علنياً والتعرف على وجهه`, correctAns: false },
        { id: 28, en: "", ar: `نظرًا لدوره المعروف، يُعتبر أبوعبيدة هو المؤسس الرئيسي لكتائب القسام`, correctAns: false },
        { id: 29, en: "", ar: `في إطار دعم المقاومة الفلسطينية، ألقى أبوعبيدة العديد من الخطب والبيانات التي تمجد المقاومة وأعمالها`, correctAns: true },
        
        // Abdel-Aziz al-Rantisi
        { id: 30, en: "", ar: `بعد وفاة شيخ أحمد ياسين، تولى عبدالعزيز الرنتيسي دورًا قياديًا بارزًا في حركة حماس، خاصة في قطاع غزة`, correctAns: true },
        { id: 31, en: "", ar: `في سياق حياته المهنية، كان عبد العزيز الرنتيسي - أحد قيادات حماس -  يعمل كطبيب متخصص`, correctAns: true },
        { id: 32, en: "", ar: `بالنظر لخبراته التعليمية، عمل عبدالعزيز الرنتيسي - أحد قيادات حماس - كأستاذ جامعي في جامعة القاهرة`, correctAns: false },
        { id: 33, en: "", ar: `ضمن الاغتيالات الإسرائيلية، تم اغتيال عبد العزيز الرنتيسي بصاروخين من طائرة مروحية أثناء تواجده في سيارته`, correctAns: true },
        
        // Shireen Abu Aqla
        { id: 34, en: "", ar: `خلال المواجهات والمظاهرات، كانت شيرين من أولى الصحفيات التي قامت بتغطية مسيرات العودة على حدود فلسطين`, correctAns: true },
        { id: 35, en: "", ar: `من بين الجوائز المرموقة، حصلت شيرين أبو عاقلة - الصحفية الشجاعة - على جائزة نوبل للسلام`, correctAns: false },
        { id: 36, en: "", ar: `تم اغتيال الصحفية شيرين أبو عاقلة من قبل قناص إسرائيلي أثناء تغطيتها أثناء تغطيتها الهجوم الإسرائيلي على جنين`, correctAns: true },
        
        // Yaser Murtaja
        { id: 36, en: "", ar: `تم إغتيال ياسر مرتجى بواسطة قوات الاحتلال الإسرائيلي أثناء تأدية واجبه الصحفي في  تغطية مسيرة العودة الكبرى`, correctAns: true },
      ],
      select: [
        // Izz ad-Din al-Qassam
        {
          id: 1,
          en: "",
          ar: "كيف استشهد عزالدين القسام؟",
          choices: [
            { id: 1, en: "", ar: "خلال مواجهة مع قوات الاحتلال الإسرائيلي", isCorrect: false },
            { id: 2, en: "", ar: "خلال مواجهة مع قوات الاحتلال البريطاني في فلسطين", isCorrect: true },
            { id: 3, en: "", ar: "في انفجار عرضي", isCorrect: false },
            { id: 4, en: "", ar: "بسبب مرض مفاجئ", isCorrect: false },
          ],
        },
        {
          id: 2,
          en: "",
          ar: `بماذا استُلهمت حركة حماس لتسمية كتائبها العسكرية باسم "كتائب القسام"؟`,
          choices: [
            { id: 1, en: "", ar: "نسبةً إلى مؤسس حماس", isCorrect: false },
            { id: 2, en: "", ar: "نسبةً إلى أحد قادة الثورة الفلسطينية", isCorrect: false },
            { id: 3, en: "", ar: "نسبةً إلى عزالدين القسام", isCorrect: true },
            { id: 4, en: "", ar: "نسبةً إلى المدينة المقدسة", isCorrect: false },
          ],
        },
        {
          id: 3,
          en: "",
          ar: `أين نشط عزالدين القسام قبل فلسطين؟`,
          choices: [
            { id: 1, en: "", ar: "في لبنان", isCorrect: false },
            { id: 2, en: "", ar: "في الأردن", isCorrect: false },
            { id: 3, en: "", ar: "في سوريا مقاومًا الاستعمار الفرنسي", isCorrect: true },
            { id: 4, en: "", ar: "في مصر", isCorrect: false },
          ],
        },
        {
          id: 4,
          en: "",
          ar: `هل كان الشهيد عزالدين القسام من المؤسسين الأساسيين لحماس؟`,
          choices: [
            { id: 1, en: "", ar: "نعم", isCorrect: false },
            { id: 2, en: "", ar: "لا", isCorrect: true },
            { id: 3, en: "", ar: "لا يمكن التأكيد", isCorrect: false },
            { id: 4, en: "", ar: "كان من المؤسسين الثانويين", isCorrect: false },
          ],
        },
        {
          id: 5,
          en: "",
          ar: `لمن تنسب المقولة "وإنه لجهاد نصر أو استشهاد" التي يرددها أبو عبيدة؟`,
          choices: [
            { id: 1, en: "", ar: "الشيخ أحمد ياسين", isCorrect: false },
            { id: 2, en: "", ar: "الشهيد عزالدين القسام", isCorrect: true },
            { id: 3, en: "", ar: "صلاح شحادة", isCorrect: false },
            { id: 4, en: "", ar: "ياسر عرفات", isCorrect: false },
          ],
        },
        
        // Yahya Ayyash
        {
          id: 6,
          en: "",
          ar: `ما اللقب الذي كان يُطلق على يحيى عياش؟`,
          choices: [
            { id: 1, en: "", ar: "ملك الانتفاضة", isCorrect: false },
            { id: 2, en: "", ar: "أسد فلسطين", isCorrect: false },
            { id: 3, en: "", ar: "مهندس الانتفاضة", isCorrect: true },
            { id: 4, en: "", ar: "فارس الجهاد", isCorrect: false },
          ],
        },
        {
          id: 7,
          en: "",
          ar: `كيف تم اغتيال المهندس الشهيد يحيى عياش؟`,
          choices: [
            { id: 1, en: "", ar: "بواسطة قناصة", isCorrect: false },
            { id: 2, en: "", ar: "عبر تفجير هاتفه المحمول", isCorrect: true },
            { id: 3, en: "", ar: "بواسطة طائرة بدون طيار", isCorrect: false },
            { id: 4, en: "", ar: "بواسطة متفجرات في منزله", isCorrect: false },
          ],
        },
        {
          id: 8,
          en: "",
          ar: `هل كان يحيى عياش من بين المؤسسين الأساسيين لحماس؟`,
          choices: [
            { id: 1, en: "", ar: "نعم", isCorrect: false },
            { id: 2, en: "", ar: "لا", isCorrect: true },
            { id: 3, en: "", ar: "لا يمكن التأكيد", isCorrect: false },
            { id: 4, en: "", ar: "كان من المؤسسين الثانويين", isCorrect: false },
          ],
        },

        // Abd al-Qader al-Husseini
        {
          id: 8,
          en: "",
          ar: `هل كان يحيى عياش من بين المؤسسين الأساسيين لحماس؟`,
          choices: [
            { id: 1, en: "", ar: "نعم", isCorrect: false },
            { id: 2, en: "", ar: "لا", isCorrect: true },
            { id: 3, en: "", ar: "لا يمكن التأكيد", isCorrect: false },
            { id: 4, en: "", ar: "كان من المؤسسين الثانويين", isCorrect: false },
          ],
        },
        {
          id: 9,
          en: "",
          ar: `في أي جيش كان عبدالقادر الحسيني من القادة المشهورين؟`,
          choices: [
            { id: 1, en: "", ar: "جيش الدفاع العربي", isCorrect: false },
            { id: 2, en: "", ar: "جيش الإنقاذ العربي", isCorrect: true },
            { id: 3, en: "", ar: "جيش التحرير العربي", isCorrect: false },
            { id: 4, en: "", ar: "جيش الفداء", isCorrect: false },
          ],
        },
        {
          id: 10,
          en: "",
          ar: `من هو مؤسس الهلال الأحمر الفلسطيني؟`,
          choices: [
            { id: 1, en: "", ar: "ياسر عرفات", isCorrect: false },
            { id: 2, en: "", ar: "الشيخ أحمد ياسين", isCorrect: false },
            { id: 3, en: "", ar: "عبدالقادر الحسيني", isCorrect: true },
            { id: 4, en: "", ar: "سليم الحصري", isCorrect: false },
          ],
        },

        // Naji al-Ali
        {
          id: 11,
          en: "",
          ar: `من هو الفنان المشهور الذي اشتهر برسم شخصية الكاريكاتير "حنظلة"؟`,
          choices: [
            { id: 1, en: "", ar: "ناجي العلي", isCorrect: true },
            { id: 2, en: "", ar: "غسان كنفاني", isCorrect: false },
            { id: 3, en: "", ar: "محمود درويش", isCorrect: false },
            { id: 4, en: "", ar: "صلاح جاهين", isCorrect: false },
          ],
        },
        {
          id: 12,
          en: "",
          ar: `في أي مدينة تم اغتيال الفنان ناجي العلي الذي اشتهر برسم شخصية الكاريكاتير "حنظلة"؟`,
          choices: [
            { id: 1, en: "", ar: "بيروت", isCorrect: false },
            { id: 2, en: "", ar: "دمشق", isCorrect: false },
            { id: 3, en: "", ar: "جدة", isCorrect: false },
            { id: 4, en: "", ar: "لندن", isCorrect: true },
          ],
        },
        {
          id: 13,
          en: "",
          ar: `ماذا تمثل شخصية حنظلة الفلسطينية التي أبدعها ناجي العلي؟`,
          choices: [
            { id: 1, en: "", ar: "الشهيد الفلسطيني", isCorrect: false },
            { id: 2, en: "", ar: "الطفل الذي لا يكبر", isCorrect: true },
            { id: 3, en: "", ar: "الثوار العرب", isCorrect: false },
            { id: 4, en: "", ar: "الحرية العربية", isCorrect: false },
          ],
        },

        // Mohammed Deif
        {
          id: 14,
          en: "",
          ar: `من هو القائد العسكري لكتائب القسام؟`,
          choices: [
            { id: 1, en: "", ar: "محمد الضيف", isCorrect: true },
            { id: 2, en: "", ar: "عز الدين القسام", isCorrect: false },
            { id: 3, en: "", ar: "خالد مشعل", isCorrect: false },
            { id: 4, en: "", ar: "مروان برغوثي", isCorrect: false },
          ],
        },
        {
          id: 15,
          en: "",
          ar: `هل تعرض محمد الضيف لمحاولات اغتيال من قبل إسرائيل؟`,
          choices: [
            { id: 1, en: "", ar: "نعم، مرة واحدة", isCorrect: false },
            { id: 2, en: "", ar: "لا", isCorrect: false },
            { id: 3, en: "", ar: "نعم، محاولات مكررة", isCorrect: true },
            { id: 4, en: "", ar: "لا يمكن التأكيد", isCorrect: false },
          ],
        },
        {
          id: 16,
          en: "",
          ar: `هل شارك محمد الضيف بشكل مباشر في تأسيس كتائب عز الدين القسام؟`,
          choices: [
            { id: 1, en: "", ar: "نعم، مرة واحدة", isCorrect: false },
            { id: 2, en: "", ar: "لا", isCorrect: true },
            { id: 3, en: "", ar: "شارك بشكل غير مباشر", isCorrect: false },
            { id: 4, en: "", ar: "كان من المؤسسين الثانويين", isCorrect: false },
          ],
        },

        // Sheikh Ahmed Yassin
        {
          id: 17,
          en: "",
          ar: `أحد الخيارات التالية هو أحد المؤسسين الأوائل لحركة حماس؟`,
          choices: [
            { id: 1, en: "", ar: "ياسر عرفات", isCorrect: false },
            { id: 2, en: "", ar: "مروان برغوثي", isCorrect: false },
            { id: 3, en: "", ar: "الشيخ أحمد ياسين", isCorrect: true },
            { id: 4, en: "", ar: "خالد مشعل", isCorrect: false },
          ],
        },
        {
          id: 18,
          en: "",
          ar: `كيف تم اغتيال الشيخ أحمد ياسين، أحد المؤسسين الأوائل لحركة حماس؟`,
          choices: [
            { id: 1, en: "", ar: "في هجوم بري", isCorrect: false },
            { id: 2, en: "", ar: "بغارة جوية بعد صلاة الفجر", isCorrect: true },
            { id: 3, en: "", ar: "في مواجهة مباشرة", isCorrect: false },
            { id: 4, en: "", ar: "بواسطة قناص", isCorrect: false },
          ],
        },

        // Abu Ubaida
        {
          id: 19,
          en: "",
          ar: `من هو المتحدث الرسمي باسم كتائب القسام؟`,
          choices: [
            { id: 1, en: "", ar: "أبو مازن", isCorrect: false },
            { id: 2, en: "", ar: "أبو عبيدة", isCorrect: true },
            { id: 3, en: "", ar: "أبو العباس", isCorrect: false },
            { id: 4, en: "", ar: "أبو نضال", isCorrect: false },
          ],
        },
        {
          id: 20,
          en: "",
          ar: `هل تم الكشف عن هوية أبوعبيدة - الناطق الرسمي باسم كتائب القسام - علنياً؟`,
          choices: [
            { id: 1, en: "", ar: "نعم يعرف الجميع وجهه", isCorrect: false },
            { id: 2, en: "", ar: "تم كشف هويته فقط", isCorrect: false },
            { id: 3, en: "", ar: "لا يزال مجهول الهوية", isCorrect: true },
            { id: 4, en: "", ar: "بعض المعلومات فقط", isCorrect: false },
          ],
        },
        {
          id: 21,
          en: "",
          ar: `هل ألقى أبوعبيدة - الناطق الرسمي باسم كتائب القسام - خطبًا وبيانات تمجد المقاومة الفلسطينية؟`,
          choices: [
            { id: 1, en: "", ar: "نعم، العديد منها", isCorrect: true },
            { id: 2, en: "", ar: "نعم، ولكن نادرًا", isCorrect: false },
            { id: 3, en: "", ar: "لم يظهر علنيًا أبدًا", isCorrect: false },
            { id: 4, en: "", ar: "كان يتحدث فقط عن السلام", isCorrect: false },
          ],
        },

        // Abdel-Aziz al-Rantisi
        {
          id: 22,
          en: "",
          ar: `من الرجل الذي تولى دورًا قياديًا بارزًا في حركة حماس بعد وفاة شيخ أحمد ياسين؟`,
          choices: [
            { id: 1, en: "", ar: "ياسر عرفات", isCorrect: false },
            { id: 2, en: "", ar: "عبدالعزيز الرنتيسي", isCorrect: true },
            { id: 3, en: "", ar: "محمد الضيف", isCorrect: false },
            { id: 4, en: "", ar: "خالد مشعل", isCorrect: false },
          ],
        },
        {
          id: 23,
          en: "",
          ar: `ما كانت مهنة عبد العزيز الرنتيسي - أحد قادات حماس -  قبل دخوله العمل السياسي؟`,
          choices: [
            { id: 1, en: "", ar: "مهندس", isCorrect: false },
            { id: 2, en: "", ar: "محامي", isCorrect: false },
            { id: 3, en: "", ar: "طبيب", isCorrect: true },
            { id: 4, en: "", ar: "معلم", isCorrect: false },
          ],
        },
        {
          id: 24,
          en: "",
          ar: `كيف تم استهداف عبد العزيز الرنتيسي، أحد قادات حماس؟`,
          choices: [
            { id: 1, en: "", ar: "بقنبلة على منزله", isCorrect: false },
            { id: 2, en: "", ar: "بصاروخين من طائرة مروحية", isCorrect: true },
            { id: 3, en: "", ar: "بواسطة قناص", isCorrect: false },
            { id: 4, en: "", ar: "في مواجهة مباشرة", isCorrect: false },
          ],
        },

        // Shireen Abu Aqla
        {
          id: 25,
          en: "",
          ar: `ما هي الجائزة التي حصلت عليها الصحفية الشجاعة شيرين أبو عاقلة؟`,
          choices: [
            { id: 1, en: "", ar: "جائزة نوبل للسلام", isCorrect: false },
            { id: 2, en: "", ar: "جائزة الصحافة العالمية", isCorrect: false },
            { id: 3, en: "", ar: "جائزة بوليتزر", isCorrect: false },
            { id: 4, en: "", ar: "لم تحصل على أي جائزة", isCorrect: true },
          ],
        },
        {
          id: 26,
          en: "",
          ar: `كيف توفيت الصحفية شيرين أبو عاقلة أثناء تغطيتها الهجوم الإسرائيلي على جنين؟`,
          choices: [
            { id: 1, en: "", ar: "في حادث سير", isCorrect: false },
            { id: 2, en: "", ar: "من قبل قناص إسرائيلي", isCorrect: true },
            { id: 3, en: "", ar: "بطريقة طبيعية", isCorrect: false },
            { id: 4, en: "", ar: "لم تتوفى", isCorrect: false },
          ],
        },
        {
          id: 27,
          en: "",
          ar: `كيف توفي الصحفي  ياسر مرتجى أثناء تأدية واجبه الصحفي في تغطية مسيرة العودة الكبرى؟`,
          choices: [
            { id: 1, en: "", ar: "بواسطة قوات الاحتلال", isCorrect: true },
            { id: 2, en: "", ar: "في حادث سير", isCorrect: false },
            { id: 3, en: "", ar: "بطريقة طبيعية", isCorrect: false },
            { id: 4, en: "", ar: "في هجوم بري", isCorrect: false },
          ],
        },

      ],
    },
    {
      tf: [
        // Izz Ad-Din Al-Qassam
        { id: 1, en: "", ar: "استشهد عزالدين القسام خلال مواجهة مع قوات الاحتلال البريطاني في فلسطين", correctAns: true },
        { id: 2, en: "", ar: `استُلهمت حركة حماس لتسمية كتائبها العسكرية باسم "كتائب القسام" نسبةً إلى عزالدين القسام`, correctAns: true },
        { id: 3, en: "", ar: "قبل نضاله في فلسطين، نشط عزالدين القسام في سوريا مقاومًا الاستعمار الفرنسي", correctAns: true },
        { id: 4, en: "", ar: "كان عزالدين القسام من المؤسسين الأساسيين لحماس", correctAns: false },
        { id: 5, en: "", ar: "كان عزالدين القسام قائدًا روحيًا للمقاومة الفلسطينية ضد الاستعمار البريطاني وهجرة اليهود الصهاينة إلى فلسطين", correctAns: true },
        { id: 6, en: "", ar: `"وإنه لجهاد نصر أو استشهاد"، هل هذه المقولة منسوبة للشهيد عزالدين القسام`, correctAns: true },

        // Yahya Ayyash
        { id: 7, en: "", ar: `يحيى عياش، المعروف بلقب "مهندس الانتفاضة"، كان مسؤولًا عن تخطيط وتنفيذ هجمات ضد القوات الإسرائيلية`, correctAns: true },
        { id: 8, en: "", ar: `تم اغتيال يحيى عياش من قبل الموساد الإسرائيلي عبر تفجير هاتفه المحمول`, correctAns: true },
        { id: 9, en: "", ar: `كان يحيى عياش من بين المؤسسين الأساسيين لحركة حماس`, correctAns: false },
        { id: 10, en: "", ar: `يحيى عياش، الذي عُرف ببراعته في التخطيط للهجمات، شغل منصب وزير الدفاع في السلطة الوطنية الفلسطينية`, correctAns: false },
        { id: 11, en: "", ar: `هل استخدمت الموساد الإسرائيلي طريقة تفجير هاتف خلوي لاغتيال يحيى عياش`, correctAns: true },
        
        // Abd al-Qader al-Husseini
        { id: 12, en: "", ar: `خلال فترة الصراع بين العرب واليهود في عام 1948، كان عبدالقادر الحسيني زعيمًا بارزًا للقوات الفلسطينية`, correctAns: true },
        { id: 13, en: "", ar: `استشهد عبدالقادر الحسيني في معركة القسطل، التي كانت جزءًا من حروب النكبة`, correctAns: true },
        { id: 14, en: "", ar: `كان عبدالقادر الحسيني من القادة المشهورين في جيش الإنقاذ العربي، الذي تم تشكيله لمواجهة الهجوم الصهيوني`, correctAns: true },
        { id: 15, en: "", ar: `تم تأسيس الهلال الأحمر الفلسطيني بفضل مبادرة من عبدالقادر الحسيني`, correctAns: true },
        { id: 16, en: "", ar: `هل شغل عبدالقادر الحسيني منصب رئيس السلطة الوطنية الفلسطينية في أي وقت من الأوقات`, correctAns: false },
        
        // Naji al-Ali
        { id: 17, en: "", ar: `الفنان ناجي العلي الذي اشتهر برسوماته المعبرة عن القضية الفلسطينية، هو رسّام شخصية الكاريكاتير المشهورة "حنظلة"`, correctAns: true },
        { id: 18, en: "", ar: `تم اغتيال ناجي العلي - رسّام شخصية الكاريكاتير المشهورة "حنظلة" -  في لندن البريطانية بعد مسيرة فنية طويلة`, correctAns: true },
        { id: 19, en: "", ar: `شخصية حنظلة التي أبدعها ناجي العلي، تمثل الطفل الفلسطيني الذي لا يكبر ويظل شاهدًا على معاناة شعبه`, correctAns: true },
        
        // Mohammed Deif
        { id: 20, en: "", ar: `يُعتبر محمد الضيف القائد العسكري لكتائب القسام ومن الذين قادوا عدة عمليات ضد إسرائيل`, correctAns: true },
        { id: 21, en: "", ar: `تعرض محمد الضيف - القائد العسكري - لكتائب القسام لمحاولات مكررة من إسرائيل لاغتياله`, correctAns: true },
        { id: 22, en: "", ar: `شارك محمد الضيف - القائد العسكري لكتائب القسام - بشكل مباشر في تأسيس كتائب عز الدين القسام`, correctAns: false },
        
        // Sheikh Ahmed Yassin
        { id: 23, en: "", ar: `الشيخ أحمد ياسين - الذي اشتهر بدوره القيادي - هو أحد المؤسسين الأوائل لحركة حماس`, correctAns: true },
        { id: 24, en: "", ar: `في محاولة إسرائيل للقضاء على قيادات حماس، تم اغتيال الشيخ أحمد ياسين عبر غارة جوية أثناء خروجه من المسجد`, correctAns: true },
        { id: 25, en: "", ar: `شيخ أحمد ياسين - أحد قيادات ومؤسسي حماس - كان يُعتبر الرئيس الأول للسلطة الوطنية الفلسطينية`, correctAns: false },
        
        // Abu Ubaida
        { id: 26, en: "", ar: `أبوعبيدة، المعروف في الأوساط الفلسطينية، يُعتبر المتحدث الرسمي باسم كتائب القسام`, correctAns: true },
        { id: 27, en: "", ar: `رغم التكتم على هويات النشطاء، تم الكشف عن هوية أبوعبيدة علنياً والتعرف على وجهه`, correctAns: false },
        { id: 28, en: "", ar: `نظرًا لدوره المعروف، يُعتبر أبوعبيدة هو المؤسس الرئيسي لكتائب القسام`, correctAns: false },
        { id: 29, en: "", ar: `في إطار دعم المقاومة الفلسطينية، ألقى أبوعبيدة العديد من الخطب والبيانات التي تمجد المقاومة وأعمالها`, correctAns: true },
        
        // Abdel-Aziz al-Rantisi
        { id: 30, en: "", ar: `بعد وفاة شيخ أحمد ياسين، تولى عبدالعزيز الرنتيسي دورًا قياديًا بارزًا في حركة حماس، خاصة في قطاع غزة`, correctAns: true },
        { id: 31, en: "", ar: `في سياق حياته المهنية، كان عبد العزيز الرنتيسي - أحد قيادات حماس -  يعمل كطبيب متخصص`, correctAns: true },
        { id: 32, en: "", ar: `بالنظر لخبراته التعليمية، عمل عبدالعزيز الرنتيسي - أحد قيادات حماس - كأستاذ جامعي في جامعة القاهرة`, correctAns: false },
        { id: 33, en: "", ar: `ضمن الاغتيالات الإسرائيلية، تم اغتيال عبد العزيز الرنتيسي بصاروخين من طائرة مروحية أثناء تواجده في سيارته`, correctAns: true },
        
        // Shireen Abu Aqla
        { id: 34, en: "", ar: `خلال المواجهات والمظاهرات، كانت شيرين من أولى الصحفيات التي قامت بتغطية مسيرات العودة على حدود فلسطين`, correctAns: true },
        { id: 35, en: "", ar: `من بين الجوائز المرموقة، حصلت شيرين أبو عاقلة - الصحفية الشجاعة - على جائزة نوبل للسلام`, correctAns: false },
        { id: 36, en: "", ar: `تم اغتيال الصحفية شيرين أبو عاقلة من قبل قناص إسرائيلي أثناء تغطيتها أثناء تغطيتها الهجوم الإسرائيلي على جنين`, correctAns: true },
        
        // Yaser Murtaja
        { id: 36, en: "", ar: `تم إغتيال ياسر مرتجى بواسطة قوات الاحتلال الإسرائيلي أثناء تأدية واجبه الصحفي في  تغطية مسيرة العودة الكبرى`, correctAns: true },
      ],
      select: [
        // Izz ad-Din al-Qassam
        {
          id: 1,
          en: "",
          ar: "كيف استشهد عزالدين القسام؟",
          choices: [
            { id: 1, en: "", ar: "خلال مواجهة مع قوات الاحتلال الإسرائيلي", isCorrect: false },
            { id: 2, en: "", ar: "خلال مواجهة مع قوات الاحتلال البريطاني في فلسطين", isCorrect: true },
            { id: 3, en: "", ar: "في انفجار عرضي", isCorrect: false },
            { id: 4, en: "", ar: "بسبب مرض مفاجئ", isCorrect: false },
          ],
        },
        {
          id: 2,
          en: "",
          ar: `بماذا استُلهمت حركة حماس لتسمية كتائبها العسكرية باسم "كتائب القسام"؟`,
          choices: [
            { id: 1, en: "", ar: "نسبةً إلى مؤسس حماس", isCorrect: false },
            { id: 2, en: "", ar: "نسبةً إلى أحد قادة الثورة الفلسطينية", isCorrect: false },
            { id: 3, en: "", ar: "نسبةً إلى عزالدين القسام", isCorrect: true },
            { id: 4, en: "", ar: "نسبةً إلى المدينة المقدسة", isCorrect: false },
          ],
        },
        {
          id: 3,
          en: "",
          ar: `أين نشط عزالدين القسام قبل فلسطين؟`,
          choices: [
            { id: 1, en: "", ar: "في لبنان", isCorrect: false },
            { id: 2, en: "", ar: "في الأردن", isCorrect: false },
            { id: 3, en: "", ar: "في سوريا مقاومًا الاستعمار الفرنسي", isCorrect: true },
            { id: 4, en: "", ar: "في مصر", isCorrect: false },
          ],
        },
        {
          id: 4,
          en: "",
          ar: `هل كان الشهيد عزالدين القسام من المؤسسين الأساسيين لحماس؟`,
          choices: [
            { id: 1, en: "", ar: "نعم", isCorrect: false },
            { id: 2, en: "", ar: "لا", isCorrect: true },
            { id: 3, en: "", ar: "لا يمكن التأكيد", isCorrect: false },
            { id: 4, en: "", ar: "كان من المؤسسين الثانويين", isCorrect: false },
          ],
        },
        {
          id: 5,
          en: "",
          ar: `لمن تنسب المقولة "وإنه لجهاد نصر أو استشهاد" التي يرددها أبو عبيدة؟`,
          choices: [
            { id: 1, en: "", ar: "الشيخ أحمد ياسين", isCorrect: false },
            { id: 2, en: "", ar: "الشهيد عزالدين القسام", isCorrect: true },
            { id: 3, en: "", ar: "صلاح شحادة", isCorrect: false },
            { id: 4, en: "", ar: "ياسر عرفات", isCorrect: false },
          ],
        },
        
        // Yahya Ayyash
        {
          id: 6,
          en: "",
          ar: `ما اللقب الذي كان يُطلق على يحيى عياش؟`,
          choices: [
            { id: 1, en: "", ar: "ملك الانتفاضة", isCorrect: false },
            { id: 2, en: "", ar: "أسد فلسطين", isCorrect: false },
            { id: 3, en: "", ar: "مهندس الانتفاضة", isCorrect: true },
            { id: 4, en: "", ar: "فارس الجهاد", isCorrect: false },
          ],
        },
        {
          id: 7,
          en: "",
          ar: `كيف تم اغتيال المهندس الشهيد يحيى عياش؟`,
          choices: [
            { id: 1, en: "", ar: "بواسطة قناصة", isCorrect: false },
            { id: 2, en: "", ar: "عبر تفجير هاتفه المحمول", isCorrect: true },
            { id: 3, en: "", ar: "بواسطة طائرة بدون طيار", isCorrect: false },
            { id: 4, en: "", ar: "بواسطة متفجرات في منزله", isCorrect: false },
          ],
        },
        {
          id: 8,
          en: "",
          ar: `هل كان يحيى عياش من بين المؤسسين الأساسيين لحماس؟`,
          choices: [
            { id: 1, en: "", ar: "نعم", isCorrect: false },
            { id: 2, en: "", ar: "لا", isCorrect: true },
            { id: 3, en: "", ar: "لا يمكن التأكيد", isCorrect: false },
            { id: 4, en: "", ar: "كان من المؤسسين الثانويين", isCorrect: false },
          ],
        },

        // Abd al-Qader al-Husseini
        {
          id: 8,
          en: "",
          ar: `هل كان يحيى عياش من بين المؤسسين الأساسيين لحماس؟`,
          choices: [
            { id: 1, en: "", ar: "نعم", isCorrect: false },
            { id: 2, en: "", ar: "لا", isCorrect: true },
            { id: 3, en: "", ar: "لا يمكن التأكيد", isCorrect: false },
            { id: 4, en: "", ar: "كان من المؤسسين الثانويين", isCorrect: false },
          ],
        },
        {
          id: 9,
          en: "",
          ar: `في أي جيش كان عبدالقادر الحسيني من القادة المشهورين؟`,
          choices: [
            { id: 1, en: "", ar: "جيش الدفاع العربي", isCorrect: false },
            { id: 2, en: "", ar: "جيش الإنقاذ العربي", isCorrect: true },
            { id: 3, en: "", ar: "جيش التحرير العربي", isCorrect: false },
            { id: 4, en: "", ar: "جيش الفداء", isCorrect: false },
          ],
        },
        {
          id: 10,
          en: "",
          ar: `من هو مؤسس الهلال الأحمر الفلسطيني؟`,
          choices: [
            { id: 1, en: "", ar: "ياسر عرفات", isCorrect: false },
            { id: 2, en: "", ar: "الشيخ أحمد ياسين", isCorrect: false },
            { id: 3, en: "", ar: "عبدالقادر الحسيني", isCorrect: true },
            { id: 4, en: "", ar: "سليم الحصري", isCorrect: false },
          ],
        },

        // Naji al-Ali
        {
          id: 11,
          en: "",
          ar: `من هو الفنان المشهور الذي اشتهر برسم شخصية الكاريكاتير "حنظلة"؟`,
          choices: [
            { id: 1, en: "", ar: "ناجي العلي", isCorrect: true },
            { id: 2, en: "", ar: "غسان كنفاني", isCorrect: false },
            { id: 3, en: "", ar: "محمود درويش", isCorrect: false },
            { id: 4, en: "", ar: "صلاح جاهين", isCorrect: false },
          ],
        },
        {
          id: 12,
          en: "",
          ar: `في أي مدينة تم اغتيال الفنان ناجي العلي الذي اشتهر برسم شخصية الكاريكاتير "حنظلة"؟`,
          choices: [
            { id: 1, en: "", ar: "بيروت", isCorrect: false },
            { id: 2, en: "", ar: "دمشق", isCorrect: false },
            { id: 3, en: "", ar: "جدة", isCorrect: false },
            { id: 4, en: "", ar: "لندن", isCorrect: true },
          ],
        },
        {
          id: 13,
          en: "",
          ar: `ماذا تمثل شخصية حنظلة الفلسطينية التي أبدعها ناجي العلي؟`,
          choices: [
            { id: 1, en: "", ar: "الشهيد الفلسطيني", isCorrect: false },
            { id: 2, en: "", ar: "الطفل الذي لا يكبر", isCorrect: true },
            { id: 3, en: "", ar: "الثوار العرب", isCorrect: false },
            { id: 4, en: "", ar: "الحرية العربية", isCorrect: false },
          ],
        },

        // Mohammed Deif
        {
          id: 14,
          en: "",
          ar: `من هو القائد العسكري لكتائب القسام؟`,
          choices: [
            { id: 1, en: "", ar: "محمد الضيف", isCorrect: true },
            { id: 2, en: "", ar: "عز الدين القسام", isCorrect: false },
            { id: 3, en: "", ar: "خالد مشعل", isCorrect: false },
            { id: 4, en: "", ar: "مروان برغوثي", isCorrect: false },
          ],
        },
        {
          id: 15,
          en: "",
          ar: `هل تعرض محمد الضيف لمحاولات اغتيال من قبل إسرائيل؟`,
          choices: [
            { id: 1, en: "", ar: "نعم، مرة واحدة", isCorrect: false },
            { id: 2, en: "", ar: "لا", isCorrect: false },
            { id: 3, en: "", ar: "نعم، محاولات مكررة", isCorrect: true },
            { id: 4, en: "", ar: "لا يمكن التأكيد", isCorrect: false },
          ],
        },
        {
          id: 16,
          en: "",
          ar: `هل شارك محمد الضيف بشكل مباشر في تأسيس كتائب عز الدين القسام؟`,
          choices: [
            { id: 1, en: "", ar: "نعم، مرة واحدة", isCorrect: false },
            { id: 2, en: "", ar: "لا", isCorrect: true },
            { id: 3, en: "", ar: "شارك بشكل غير مباشر", isCorrect: false },
            { id: 4, en: "", ar: "كان من المؤسسين الثانويين", isCorrect: false },
          ],
        },

        // Sheikh Ahmed Yassin
        {
          id: 17,
          en: "",
          ar: `أحد الخيارات التالية هو أحد المؤسسين الأوائل لحركة حماس؟`,
          choices: [
            { id: 1, en: "", ar: "ياسر عرفات", isCorrect: false },
            { id: 2, en: "", ar: "مروان برغوثي", isCorrect: false },
            { id: 3, en: "", ar: "الشيخ أحمد ياسين", isCorrect: true },
            { id: 4, en: "", ar: "خالد مشعل", isCorrect: false },
          ],
        },
        {
          id: 18,
          en: "",
          ar: `كيف تم اغتيال الشيخ أحمد ياسين، أحد المؤسسين الأوائل لحركة حماس؟`,
          choices: [
            { id: 1, en: "", ar: "في هجوم بري", isCorrect: false },
            { id: 2, en: "", ar: "بغارة جوية بعد صلاة الفجر", isCorrect: true },
            { id: 3, en: "", ar: "في مواجهة مباشرة", isCorrect: false },
            { id: 4, en: "", ar: "بواسطة قناص", isCorrect: false },
          ],
        },

        // Abu Ubaida
        {
          id: 19,
          en: "",
          ar: `من هو المتحدث الرسمي باسم كتائب القسام؟`,
          choices: [
            { id: 1, en: "", ar: "أبو مازن", isCorrect: false },
            { id: 2, en: "", ar: "أبو عبيدة", isCorrect: true },
            { id: 3, en: "", ar: "أبو العباس", isCorrect: false },
            { id: 4, en: "", ar: "أبو نضال", isCorrect: false },
          ],
        },
        {
          id: 20,
          en: "",
          ar: `هل تم الكشف عن هوية أبوعبيدة - الناطق الرسمي باسم كتائب القسام - علنياً؟`,
          choices: [
            { id: 1, en: "", ar: "نعم يعرف الجميع وجهه", isCorrect: false },
            { id: 2, en: "", ar: "تم كشف هويته فقط", isCorrect: false },
            { id: 3, en: "", ar: "لا يزال مجهول الهوية", isCorrect: true },
            { id: 4, en: "", ar: "بعض المعلومات فقط", isCorrect: false },
          ],
        },
        {
          id: 21,
          en: "",
          ar: `هل ألقى أبوعبيدة - الناطق الرسمي باسم كتائب القسام - خطبًا وبيانات تمجد المقاومة الفلسطينية؟`,
          choices: [
            { id: 1, en: "", ar: "نعم، العديد منها", isCorrect: true },
            { id: 2, en: "", ar: "نعم، ولكن نادرًا", isCorrect: false },
            { id: 3, en: "", ar: "لم يظهر علنيًا أبدًا", isCorrect: false },
            { id: 4, en: "", ar: "كان يتحدث فقط عن السلام", isCorrect: false },
          ],
        },

        // Abdel-Aziz al-Rantisi
        {
          id: 22,
          en: "",
          ar: `من الرجل الذي تولى دورًا قياديًا بارزًا في حركة حماس بعد وفاة شيخ أحمد ياسين؟`,
          choices: [
            { id: 1, en: "", ar: "ياسر عرفات", isCorrect: false },
            { id: 2, en: "", ar: "عبدالعزيز الرنتيسي", isCorrect: true },
            { id: 3, en: "", ar: "محمد الضيف", isCorrect: false },
            { id: 4, en: "", ar: "خالد مشعل", isCorrect: false },
          ],
        },
        {
          id: 23,
          en: "",
          ar: `ما كانت مهنة عبد العزيز الرنتيسي - أحد قادات حماس -  قبل دخوله العمل السياسي؟`,
          choices: [
            { id: 1, en: "", ar: "مهندس", isCorrect: false },
            { id: 2, en: "", ar: "محامي", isCorrect: false },
            { id: 3, en: "", ar: "طبيب", isCorrect: true },
            { id: 4, en: "", ar: "معلم", isCorrect: false },
          ],
        },
        {
          id: 24,
          en: "",
          ar: `كيف تم استهداف عبد العزيز الرنتيسي، أحد قادات حماس؟`,
          choices: [
            { id: 1, en: "", ar: "بقنبلة على منزله", isCorrect: false },
            { id: 2, en: "", ar: "بصاروخين من طائرة مروحية", isCorrect: true },
            { id: 3, en: "", ar: "بواسطة قناص", isCorrect: false },
            { id: 4, en: "", ar: "في مواجهة مباشرة", isCorrect: false },
          ],
        },

        // Shireen Abu Aqla
        {
          id: 25,
          en: "",
          ar: `ما هي الجائزة التي حصلت عليها الصحفية الشجاعة شيرين أبو عاقلة؟`,
          choices: [
            { id: 1, en: "", ar: "جائزة نوبل للسلام", isCorrect: false },
            { id: 2, en: "", ar: "جائزة الصحافة العالمية", isCorrect: false },
            { id: 3, en: "", ar: "جائزة بوليتزر", isCorrect: false },
            { id: 4, en: "", ar: "لم تحصل على أي جائزة", isCorrect: true },
          ],
        },
        {
          id: 26,
          en: "",
          ar: `كيف توفيت الصحفية شيرين أبو عاقلة أثناء تغطيتها الهجوم الإسرائيلي على جنين؟`,
          choices: [
            { id: 1, en: "", ar: "في حادث سير", isCorrect: false },
            { id: 2, en: "", ar: "من قبل قناص إسرائيلي", isCorrect: true },
            { id: 3, en: "", ar: "بطريقة طبيعية", isCorrect: false },
            { id: 4, en: "", ar: "لم تتوفى", isCorrect: false },
          ],
        },
        {
          id: 27,
          en: "",
          ar: `كيف توفي الصحفي  ياسر مرتجى أثناء تأدية واجبه الصحفي في تغطية مسيرة العودة الكبرى؟`,
          choices: [
            { id: 1, en: "", ar: "بواسطة قوات الاحتلال", isCorrect: true },
            { id: 2, en: "", ar: "في حادث سير", isCorrect: false },
            { id: 3, en: "", ar: "بطريقة طبيعية", isCorrect: false },
            { id: 4, en: "", ar: "في هجوم بري", isCorrect: false },
          ],
        },

      ],
    },
  ];