import React, { useEffect, useState } from "react";
import "./Results.css";
import Vector from "../assets/ToofanVector.svg";
import Star from "../assets/Star.svg";
import { useLanguageThemeContext } from "../LanguageThemeContext";
import { useNavigate } from "react-router-dom";
import { getData } from "../fetch";
import Loading from "../Components/Loading";
import Footer from "../Components/Footer";

function Results() {
  const [resultData, setResultData] = useState({ count: null, rank: null });
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const { language, name, score, latestScore, id } = useLanguageThemeContext();

  useEffect(() => {
    getData("users").then((users) => {
      const userArray = Object.keys(users).map((userId) => ({
        id: userId,
        ...users[userId],
      }));
      const sortedUsers = userArray.sort((a, b) => b.score - a.score);
      const userRankIndex = sortedUsers.findIndex((user) => user.id === id);
      console.log(sortedUsers);
      console.log(userRankIndex);
      setResultData({
        count: userArray.length,
        rank: userRankIndex !== -1 ? userRankIndex + 1 : "Not Found",
      });
      console.log(resultData);
    });
  }, [getData]);

  useEffect(() => {
    if (resultData.rank && resultData.count) setLoaded(true);
  }, [resultData]);

  if (loaded)
    return (
      <div className="StackContainer">
        <div className="CloseBtn" onClick={() => navigate("/")} />
        <div
          className="LeaderBoardBtn"
          onClick={() => navigate("/leaderboard")}
        />
        <div className="ResultHeader StackContainer">
          <img src={Vector} className="CardToofanLogo" alt="Toofan Vector" />
        </div>

        <div className="StackContainer card">
          <div className="PlayerName">{name}</div>
          <div className="PlayerResult StackContainer">
            <div className="PlayerResultRow1">
              <img src={Star} className="ResultPageStar" alt="Star" />
              <div className="TotalPoints">{score}</div>
            </div>
            <div className="PlayerResultRow2">+ {latestScore}</div>
          </div>
          <hr className="ResultLine" />
          {language === "ar" ? (
            <div className="PlayerRank">
              ترتيبك <span className="PlayerRankBlue">{resultData.rank}</span>{" "}
              على <span className="PlayerRankBlue">{resultData.count}</span>{" "}
              لاعب
            </div>
          ) : (
            <div className="PlayerRank">
              You're in{" "}
              <span className="PlayerRankBlue">{resultData.rank}</span> place
              out of <span className="PlayerRankBlue">{resultData.count}</span>{" "}
              players
            </div>
          )}
          <div className="ResultShareBtn" onClick={() => navigate("/share")} />
          {language === "ar" ? (
            <div className="ResultShareText">
              ساهم بنشر القضية عن
              <br />
              طريق مشاركة رابط اللعبة
            </div>
          ) : (
            <div className="ResultShareText">
              Help spread the cause
              <br />w by sharing the game link
            </div>
          )}
          <Footer opacity />
        </div>
      </div>
    );
  else return <Loading />;
}

export default Results;
