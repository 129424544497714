import React from "react";
import "./SharePage.css";
import Vector from "../assets/ToofanVector.svg";
import Footer from "../Components/Footer";
import { useLanguageThemeContext } from "../LanguageThemeContext";
import { useNavigate } from "react-router-dom";

function SharePage() {
  const { language } = useLanguageThemeContext();
  const navigate = useNavigate();

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText("toofanalaqsa.net");
  };

  return (
    <div className="StackContainer ImageGradient">
      <div className="CloseBtn" onClick={() => navigate("/")} />
      <div
        className="LeaderBoardBtn"
        onClick={() => navigate("/leaderboard")}
      />
      <img src={Vector} className="ShareToofanLogo" alt="Toofan Vector" />
      <div className={language === "ar" ? "ShareText rtl" : "ShareText ltr"}>
        {language === "ar" ? (
          <div>
            ساهم بنشر القضية عن
            <br />
            طريق مشاركة رابط اللعبة
          </div>
        ) : (
          <div>
            Help spread the cause
            <br />
            by sharing the game link
          </div>
        )}
      </div>

      <div className="ShareLink">toofanalaqsa.net</div>

      <div className="ActionBtn ShareBtnMargin" onClick={copyLinkToClipboard}>
        {" "}
        {language === "ar" ? "نسخ" : "Copy"}
      </div>

      <Footer />
    </div>
  );
}

export default SharePage;
